import React from "react";
import { connect } from "react-redux";

import { getListForTable, getIndicadores } from "../../store/actions/dashboardActions.js";
import { getExcelGuias, LimpiarNombreExcel } from "../../store/actions/gestionPaquetesActions";

import Grafica from "./Graficas.js";


// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Language from "@material-ui/icons/Language";
import MoreIcon from '@material-ui/icons/More';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import GetAppIcon from '@material-ui/icons/GetApp';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";


import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const [ enRuta, setEnRuta ] = React.useState(0);
  const [ totalGuias, setTotalGuias ] = React.useState(0);
  const [ idSucursalLogueada, setIdSucursalLogueada] = React.useState(localStorage.getItem('sucursal'));
  const [ loading, setLoading ] = React.useState(false);
  const [ disabled, setDisabled ] = React.useState(false);
  const [ fileSrc, setFileSrc] = React.useState(null);

  const [ data, setData ] = React.useState(null);
  
  const [ ead, setead ] = React.useState(0);
  const [ rad, setrad ] = React.useState(0);
  
  const classes = useStyles();

  React.useEffect(() => {    
    // if(idSucursalLogueada>1){
        props.LimpiarExcel();
        setLoading(true);
        props.getIndicadores(idSucursalLogueada);
        props.getListForTable(idSucursalLogueada);
    // }
  }, []);

  React.useEffect(()=>{
    if(props.indicadores){
      console.log(props.indicadores);
      setead(props.indicadores.ead);

      setrad(props.indicadores.rad);
    }
  },[props.indicadores]);

  React.useEffect(() => {
    setData(props.listData);    
    setLoading(false);

    if(props.listData){
      setEnRuta(props.listData.Repartos);
      setTotalGuias(props.listData.Tabla.totales.izq);
    }
  }, [props.listData]);

  React.useEffect(() => {
    if(props.nombreExcel!=null){
        setLoading(false);
        setDisabled(false);
        setFileSrc(process.env.REACT_APP_API_BASE_URL + "gestionpaquetes/dwnExcel.php?file=" + props.nombreExcel); 
        props.LimpiarExcel();       
    }
}, [props.nombreExcel]);

  const formattedData = data == null ? null : (        
      data.Tabla.izq.map((prop, key) => {
          let color = parseInt(prop.total) > 0 ? { cursor:'pointer', color:"#4caf50" } : { cursor:'', color:"#000" };    
          return [
              prop.titulo,
              prop.total,
              <Tooltip title="Descargar Excel" placement="top">
              <GetAppIcon disabled={disabled} key={prop.key} style={color} onClick={() => {
                  // let obj = data.data.find(o => o.bitacora === prop.bitacora);
                  if(parseInt(prop.total) > 0){
                      downloadExcel(prop.key);
                  }
              }}/>
              </Tooltip>
          ]
      })
  );

  const downloadExcel = (tipo) =>{
    setLoading(true);
    setDisabled(true);
    props.getExcelGuias(tipo, idSucursalLogueada);
  };

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <LocalShippingIcon/>
              </CardIcon>
              <p className={classes.cardCategory}>En Ruta</p>
              <h3 className={classes.cardTitle}>{enRuta}</h3>
            </CardHeader>
            <CardFooter stats onClick={() => {
                // verRecolecciones("REALIZADO");
            }}>
                <div className={classes.stats}>
                    
                </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="success" icon>
            <CardIcon color="success">
              <Language />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Inventario
            </h4>
          </CardHeader>
        <CardBody>
          <GridContainer justify="space-between">
          <GridItem xs={12} sm={12} md={5}>
            <div className={classes.cardHeader}>
            <h4 className={classes.cardTitle}>En sucursal <label>( {totalGuias} Guías )</label></h4>
            </div>
            <br />
            <Table
              tableData = { 
                formattedData === null ? 
                [[
                  "Almacen Destino EAD",
                  0,
                  <GetAppIcon/>
                ],[
                  "Almacen Destino OCU",
                  0,
                  <GetAppIcon/>
                ],[
                  "Almacen Trasbordo",
                  0,
                  <GetAppIcon/>
                ],[
                  "Almacen Origen (Registradas)",
                  0,
                  <GetAppIcon/>
                ],[
                  "Por Recibir",
                  0,
                  <GetAppIcon/>
                ],[
                  "En Transito",
                  0,
                  <GetAppIcon/>
                ],[
                  "Almacen Origen (Por Recibir)",
                  0,
                  <GetAppIcon/>
                ]] : formattedData                
              }
                  />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Grafica ead={ead} rad={rad} />
          </GridItem>
          </GridContainer>
        </CardBody>
        </Card>
      </GridItem>
      </GridContainer>
      <div style={{display: 'none'}}>
        <iframe src={fileSrc} />
      </div>  
    </div>
    
  );
}

const mapStateToProps = (state) => {
  return {
      "listData": state.dashboard.dashboard_Data,
      "MensajeError": state.dashboard.dashboard_Error,
      "nombreExcel": state.gestionPaquetes.gestionPaquetes_NombreExcel,      
      "indicadores": state.dashboard.dashboard_Data_Indicadores,      
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      "getListForTable": (sucursal) => dispatch(getListForTable(sucursal)),
      "getExcelGuias" : (tipo,sucursal) => dispatch(getExcelGuias(tipo,sucursal)),
      "LimpiarExcel": () => dispatch(LimpiarNombreExcel()),
      "getIndicadores": (sucursal) => dispatch(getIndicadores(sucursal)),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);
