import http from '../../http';

const vRutaCatalogos = 'catalogos/';
const vHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

export const getSucursales = () =>{
    return(dispatch, getState) => {
        http.get(vRutaCatalogos + 'sucursales/listadosucursales.php', vHeader)
        .then(function (response){
            // console.log(response);
            dispatch({
                type: "CATALOGO_SUCURSAL_LIST_SUCCESS", payload: {
                    data: response.data,
                }
            });
        })
        .catch(function (error) {
            let errorMessage = "";
            if (error.response) {
                errorMessage = error.response.data.mensaje;
            } else if (error.request) {
                errorMessage = "The request was made but no response was received";
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
            }
            dispatch({ type: 'CATALOGO_SUCURSAL_ERROR', payload: { error: errorMessage } });
        })
        .finally(function () {
            //dispatch({ type: 'HIDE_LOADING' });
        }); 
    }
}

export const closeMessages = () => {
    return (dispatch, getState) => {
        dispatch({type: "CATALOGO_SUCURSAL_CLOSE_MESSAGES"});
    }
}