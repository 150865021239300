import http from '../../http';

const vHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};



const postLogin = (dispatch, credentials, actionType) => {
    var httpParams = JSON.stringify({ usuario: credentials.user, password: credentials.password });

    dispatch({ type: 'LOGIN_LOADING' });
    
    http.post('login.php', httpParams, vHeader)
        .then(function (response) {
            // console.log(response);
            dispatch({
                type: actionType + "_SUCCESS", payload: {
                    accessToken: response.data.token,
                    userId: response.data.idUsuario,
                    user: credentials.user,
                    userFullName: response.data.nombreUsuario,
                    sucursal: response.data.sucursal,
                    nombresucursal: response.data.nombresucursal,
                    // permisos: JSON.stringify(response.data.permisos),
                    // avatar:response.data.avatar
                }
            });
        })
        .catch(function (error) {
            // console.log(error.response);
            let errorMessage = "";
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                //console.log(error.response.data.mensaje);                    
                //console.log(error.response.status);
                //console.log(error.response.headers);
                errorMessage = error.response.data.mensaje;
              } else if (error.request) {
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                //console.log(error.request);
                errorMessage = "The request was made but no response was received";
              } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
              }

            //   console.log(errorMessage);
              dispatch({ type: actionType + '_ERROR', payload: { error: errorMessage } });
        })
        .finally(function () {
            // finaly code
        });        
}

export const cambiarsucursal = (idsucursal,nombresucursal) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'CAMBIO_SUCURSAL', payload: {
                sucursal: idsucursal,
                nombresucursal: nombresucursal                
            }
        });
    }
}

export const login = (credentials) => {
    return (dispatch, getState) => {
        postLogin(dispatch, credentials, 'LOGIN');
    }
}

export const logout = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'LOGOUT_SUCCESS' });
    }
}

export const lock = () => {
    return (dispatch, getState) => {
        dispatch({ type: 'LOCK_SUCCESS' });
    }
}

export const unlock = (credentials) => {
    return (dispatch, getstate) => {
        postLogin(dispatch, credentials, 'UNLOCK')
    }
}