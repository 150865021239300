import React from "react";
import { connect } from "react-redux";

import { f_ObtenerReparto, f_LimpiarReparto, closeMessages } from "../../../store/actions/monitoreoActions";
import { logout, lock } from "../../../store/actions/authActions";

import DetalleEnvio from "../../Shared/DetalleEnvio.js";
import Mapa from "../../Shared/Mapa.js";
import SweetAlert from "react-bootstrap-sweetalert";

import { makeStyles } from "@material-ui/core/styles";

// import Edit from "@material-ui/icons/Edit";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";

import Spinner from "components/Spinner/Spinner.js";

import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js"; 
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import stylesTable2 from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles({styles,stylesTable2});
// const useStylesTable2 = makeStyles(stylesTable2);

function MonitoreoMapa(props){
    const [formData, setFormData] = React.useState(null);
    const [idReparto,setIdReparto] = React.useState(props.idReparto);
    const [folioReparto,setFolioReparto] = React.useState(props.folioReparto);
    
    const [ unidad, setUnidad ]  = React.useState(props.unidad);
    const [ fecha, setFecha ]  = React.useState(props.fecha);
    const [ sucursal, setSucursal ]  = React.useState(props.sucursal);


    const [ conductor, setConductor ]  = React.useState("");
    const [ totalGuias, setTotalGuias ]  = React.useState(0);
    const [ entregados, setEntregados ]  = React.useState(0);
    const [ devueltas, setDevueltas ]  = React.useState(0);
    const [ pendientes, setPendientes ]  = React.useState(0);
    const [ liquidado, setLiquidado ]  = React.useState(0);

    const [ numeroGuia, setNumeroGuia ] = React.useState("");
    const [ numeroGuiaBuscar, setNumeroGuiaBuscar ] = React.useState("");

    const [ mostrarDetalle, setMostrarDetalle ] = React.useState(false);
    const [ showMapa, setShowMapa ] = React.useState(true);
    
    const [ cantEntregadas, setCantEntregadas ]  = React.useState(0);
    const [ cantDevueltas, setCantDevueltas ]  = React.useState(0);
    const [ cantPendientes, setCantPendientes ]  = React.useState(0);

    const useStylesTable = makeStyles({
        root: {
            width: '100%',
        },
        tableWrapper: {
            maxHeight: 440,
            overflow: 'auto',
        },
    });

    const classes = useStyles();
    const classesTable = useStylesTable();

    React.useEffect(() => {
        props.scrollToTop();
        if(unidad){
            // console.log(unidad+"/"+sucursal+"/"+fecha);
            // props.f_ObtenerReparto(idReparto,folioReparto,numeroGuiaBuscar);
            props.f_ObtenerReparto(unidad,sucursal,fecha,numeroGuiaBuscar);
        }
    }, []);

    React.useEffect(() => {
        setFormData(props.repartoData);
        if(props.repartoData){
            // console.log(props.repartoData);
            setTotalGuias(props.repartoData.resultado.detalle.length);
            setConductor(props.repartoData.resultado.reparto.conductor);
            setLiquidado(props.repartoData.resultado.reparto.liquidado);
            setUnidad(props.repartoData.resultado.reparto.nomunidad);
            f_Detalle(props.repartoData.resultado.detalle);
            setShowMapa(true);
        }
    }, [props.repartoData]);

    const scrollToTop = () =>{
        props.scrollToTop();
    }

    const dataMapa = props.repartoData == null ? [] : props.repartoData.resultado.mapa;    

    const formattedData = props.repartoData == null ? [] : (

        props.repartoData.resultado.detalle.map((prop, key) => {
            return [
                <label style={{ color:"#000" }}>{ prop.guia }</label>,
                <div key={key}>
                    <h6>{ prop.estado=="E" ? "Entregado" :  prop.estado=="D" ?  prop.motivo  : "Pendiente"  }</h6><label>{prop.horaentrega} {prop.estado!="" ? "hs" : ""}</label>
                </div>,
                <Button simple color="success" style={{ width:"3px", height:"3px" }} key = {key} onClick={() => {
                    editButtonClick(prop.guia);
                }}>
                    <VisibilityIcon className={classes.icon} />
                </Button>
            ]
        })    
    );

    const editButtonClick = (id) => {
        setNumeroGuia(id);
        setMostrarDetalle(true);
    }

    const returnToMapa = () => {
        setMostrarDetalle(false);
    }

    const returnToListadoRepartos = () =>{
        props.f_LimpiarReparto();
        props.scrollToTop();

        props.returnToRepartos();
    }

    const handlePress = (value) =>{
        if(value.length>12){
            setNumeroGuiaBuscar(value);
            props.f_ObtenerReparto(unidad,sucursal,fecha,value);
        }else if(value==""){
            setNumeroGuiaBuscar(value);
            props.f_ObtenerReparto(unidad,sucursal,fecha,value);
        }
    }

    function f_Detalle(detalle){
        let Entregadas = 0;
        let Devueltas = 0;
        let Pendientes = 0;
        let TotalGuias = detalle.length;
        
        detalle.map((prop, key) => {
            if(prop.estado=="E"){
                Entregadas++;
            }else if(prop.estado=="D"){
                Devueltas++;
            }
        });

        let porEntregados = ( Entregadas / TotalGuias) * 100;
        let porDevueltas = ( Devueltas / TotalGuias) * 100;

        Pendientes = parseFloat(detalle.length) - (parseFloat(Entregadas) + parseFloat(Devueltas));
        
        let porPendientes = ( Pendientes / TotalGuias) * 100;

        setEntregados(porEntregados.toFixed(2));
        setDevueltas(porDevueltas.toFixed(2));
        setPendientes(porPendientes.toFixed(2));

        setCantEntregadas("Total Entregadas: " + Entregadas);
        setCantDevueltas("Total Devueltas: " + Devueltas);
        setCantPendientes("Total Pendientes: " + Pendientes);
    }

    const hideAlert = () => { props.closeMessages(); };
    const handleLogOut = () => { props.closeMessages(); props.logout(); };

    let errorMessage = !props.MensajeError ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
          onCancel={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.MensajeError}
        </SweetAlert>
      );

    const percentage = 66;    
    
    const maps = <Mapa data={dataMapa} />;

    const cardContent = (!formData) ? ((<Spinner width={"80%"} height={5} color={"black"} loading />)) : <div><GridContainer>
        <GridItem xs={12} sm={6} md={6}>
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={8}>
                            <h4>Unidad:&nbsp;{ unidad }</h4>
                            <label style={{color:"#000"}}>{ conductor }</label>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} style={{borderLeft :"1px solid #ccc", textAlign:"center"}} >
                            <h3>{ (parseFloat(liquidado) > 0) ? "Cerrada" : "En Curso"}</h3>
                            {/* <label style={{color:"#000"}}>Demorada</label> */}
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            <Card>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3} style={{ textAlign:"center" }}>
                            <h6>GUIAS</h6>
                            <label style={{fontSize:"22px", color:"#000"}}>{ totalGuias }</label>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={9} style={{borderLeft :"1px solid #ccc"}} >
                            <GridContainer>
                                {/* <GridItem xs={12} sm={12} md={5}>
                                    <div style={{width:"45%", height: "45%" }}>
                                        <CircularProgressbarWithChildren
                                            value={entregados}
                                            styles={buildStyles({
                                                pathColor: "green",
                                                trailColor: "#eee",
                                                strokeLinecap: "butt"
                                            })}
                                        >
                                       
                                            <CircularProgressbar
                                                value={devueltas}
                                                styles={buildStyles({
                                                    pathColor: "orange",
                                                    trailColor: "transparent",
                                                    strokeLinecap: "butt"
                                                })}
                                            />
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </GridItem> */}
                                <GridItem xs={12} sm={12} md={4}>
                                    <Tooltip title={ cantEntregadas } placement="left-start">
                                        <label style={{fontSize:"11px", color:"#000"}}>Entregados</label>
                                    </Tooltip>
                                    <br/>
                                    <Tooltip title={ cantDevueltas } placement="left-start">
                                        <label style={{fontSize:"11px", color:"#000"}}>Entregas Fallidas</label>
                                    </Tooltip>
                                    <br/>
                                    <Tooltip title={ cantPendientes } placement="left-start">
                                        <label style={{fontSize:"11px", color:"#000"}}>Pendientes</label>
                                    </Tooltip>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3} style={{ textAlign:"center"}}>
                                    <label style={{marginLeft:"35px", fontSize:"13px", color:"#000"}}>{ entregados }%</label><br/>
                                    <label style={{marginLeft:"35px", fontSize:"13px", color:"#000"}}>{ devueltas }%</label><br/>
                                    <label style={{marginLeft:"35px", fontSize:"13px", color:"#000"}}>{ pendientes }%</label>
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </GridItem>
    </GridContainer>
    <GridContainer>
    
        <GridItem xs={12} sm={12} md={4}>
            <Card>
                <CardBody>
                <GridItem xs={12} sm={12} md={12} >
                    <CustomInput
                        labelText="Buscar Guía"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            onKeyUp: e => {
                                e.preventDefault();
                                if (e.which === 13 || e.keyCode === 13) {
                                    handlePress(e.target.value);
                                }
                            },                            
                            autoComplete: "off"                            
                        }}
                    />
                </GridItem>
                <div className={classesTable.tableWrapper}>
                    <Table 
                        hover
                        striped
                        tableHead={[
                        "Guia",
                        "Estado",
                        "Ver"
                        ]}
                        tableData = {
                            formattedData
                        }
                        customCellClasses={[classes.center, classes.left, classes.center]}
                        customClassesForCells={[0, 5, 5]}
                        customHeadCellClasses={[
                            classes.center,
                            classes.left,
                            classes.center
                        ]}
                        customHeadClassesForCells={[0, 5, 5]}
                    />
                    </div>
                    
                </CardBody>
            </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
            <Card>
                <CardBody>
                    { showMapa ? maps : "" }
                </CardBody>
            </Card>
        </GridItem>
    </GridContainer>
    <div style={{ width:"100%",  textAlign:"center" }}>
        <Button
            color="warning"
            onClick={returnToListadoRepartos}
            className={classes.registerButton}
        >
            Regresar
        </Button>
    </div>
    </div>;

    const detalleinfo = <DetalleEnvio Guia={numeroGuia} returnToMapa={returnToMapa} scrollToTop={scrollToTop}></DetalleEnvio>;

    return (
        <React.Fragment>
            { errorMessage }
            { mostrarDetalle ? detalleinfo : cardContent }
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        // "listData": state.catalogoCategorias.catalogoCategorias_List,
        // "listData_Error": state.catalogoCategorias.catalogoCategorias_List_Error,
        "repartoData": state.monitoreo.monitoreo_Reparto_Data,
        "MensajeError": state.monitoreo.monitoreo_Error_Message,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // "getListForTable": () => dispatch(getListForTable()),
        // "f_ObtenerReparto": (idreparto,folioreparto,guia) => dispatch(f_ObtenerReparto(idreparto,folioreparto,guia)),
        "f_ObtenerReparto": (unidad,sucursal,fecha,guia) => dispatch(f_ObtenerReparto(unidad,sucursal,fecha,guia)),
        "f_LimpiarReparto": () => dispatch(f_LimpiarReparto()),
        "closeMessages": () => dispatch(closeMessages()),
        "logout": () => dispatch(logout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoreoMapa);