import React from "react";
import { connect } from "react-redux";

import { f_ObtenerRepartos, closeMessages } from "../../../store/actions/monitoreoActions";
import { logout, lock } from "../../../store/actions/authActions";

import MonitoreoMapa from "./MonitoreoMapa.js";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Icon from "@material-ui/core/Icon";
//Icon
import Warning from "@material-ui/icons/Warning";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Spinner from "components/Spinner/Spinner.js";

//Iconos
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Tooltip from '@material-ui/core/Tooltip';

// import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

// import CircleBar from "components/CircleBar/CircleBar.js";

import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
  } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const useStyles = makeStyles(styles);

// function BootstrapTooltip(props) {
//     const classes = useStylesBootstrap();

//     return <Tooltip arrow classes={classes} {...props} />;
// }

function MonitoreoForm(props){
    const [formData, setFormData] = React.useState(null);
    const mainPanel = React.createRef();

    const [ sucursal, setSucursal ] = React.useState(props.Sucursal);
    const [ fecha, setFecha ] = React.useState(props.Fecha);

    const [totalRepartos, setTotalRepartos] = React.useState(0);
    const [totalGuias, setTotalGuias] = React.useState(0);
    const [totalEntregadas, setTotalEntregadas] = React.useState(0);
    const [totalDevueltas, setTotalDevueltas] = React.useState(0);
    const [totalPendientes, setTotalPendientes] = React.useState(0);
    const [cantEntregadas, setCantEntregadas] = React.useState(0);
    const [cantDevueltas, setCantDevueltas] = React.useState(0);
    const [cantPendientes, setCantPendientes] = React.useState(0);
    const [totalEnCurso, setTotalEnCurso] = React.useState(0);
    const [totalEnSucursal, setTotalEnSucursal] = React.useState(0);
    const [mostrarMapa, setmostrarMapa] = React.useState(false);

    const [folioReparto, setFolioReparto] = React.useState(0);
    const [idReparto, setIdReparto] = React.useState(0);
    const [unidad, setUnidad] = React.useState(0);

    const classes = useStyles();
    const percentage = 66;

    
    React.useEffect(() => {
        props.scrollToTop();
     if(sucursal > 0){
        props.f_ObtenerRepartos(sucursal,fecha);
     }
    }, []);

    // if data changes then set to the state the new values
  React.useEffect(() => {
    setFormData(props.repartosData);
    if(props.repartosData){
        // console.log(formData);
        setTotalRepartos(props.repartosData.resultado.repartos.length);
        setTotalGuias(props.repartosData.resultado.totales.TotalGuias);
        setTotalEnCurso(props.repartosData.resultado.totales.TotalLiqudados);

        setTotalEntregadas(props.repartosData.resultado.totales.Entregadas);
        setTotalDevueltas(props.repartosData.resultado.totales.Devueltas);
        setTotalPendientes(props.repartosData.resultado.totales.Pendientes);
        
        setCantEntregadas("Total Entregadas: " + props.repartosData.resultado.totales.CantEntregadas);
        setCantDevueltas("Total Devueltas: " + props.repartosData.resultado.totales.CantDevueltas);
        setCantPendientes("Pendientes: " + props.repartosData.resultado.totales.CantPendientes);

        setTotalEnSucursal(props.repartosData.resultado.totales.EnSucursal);
    }
  }, [props.repartosData]);

  const scrollToTop = () => {
    props.scrollToTop(); 
  }

  const handleMostrar = (div,ocultar,mostrar) =>{
    document.getElementById(div).style.display = "";
    document.getElementById(ocultar).style.display = "none";
    document.getElementById(mostrar).style.display = "";
  }

  const handleOcultar = (div,mostrar,ocultar) =>{
    document.getElementById(div).style.display = "none";
    document.getElementById(ocultar).style.display = "none";
    document.getElementById(mostrar).style.display = "";
  }

  const cards = props.repartosData == null ? [] : (
        props.repartosData.resultado.repartos.map((prop, key) => {
            let guiasTot = parseFloat(prop.totalguias);
            let entreTot = parseFloat(prop.totalentregadas);
            let devueTot = parseFloat(prop.totaldevueltas);
            let pendiTot = parseFloat(guiasTot - (entreTot + devueTot));
            //let percentage = ((entreTot + devueTot) / guiasTot) * 100;

            return [ 
                    <Card key = { key }>
                        <CardBody>                            
                                <div style={{float:"left", width:"100px", textAlign:"center"}}>
                                    <Tooltip title="MOSTRAR REPARTOS" placement="top">
                                        <ExpandMoreIcon id={`exp_${key}`} style={{ fontSize: 40, marginTop:10 }} onClick={()=>{
                                            handleMostrar("div_"+key,"exp_"+key,"les_"+key);
                                        }}/>
                                    </Tooltip>
                                    <ExpandLessIcon id={`les_${key}`} style={{ fontSize: 40, marginTop:10, display:"none" }} onClick={()=>{
                                        handleOcultar("div_"+key,"exp_"+key,"les_"+key);
                                    }}/>
                                </div>
                                <div key = { key } style={{cursor:"pointer"}} onClick={()=>{
                                    handleRutaClick(prop.id, prop.folio,prop.unidad);
                                }}>
                                    <div style={{float:"left", width:"200px"}}>
                                        <h6>UNIDAD</h6>
                                        <label>{prop.unidad}</label>
                                    </div>
                                    <div style={{float:"left", width:"200px"}}>
                                        <h6>{prop.conductor}</h6>
                                    </div>
                                    <div style={{float:"left", width:"80px"}}>
                                        <div style={{ width:"70%", height: "70%" }}>
                                            <CircularProgressbar 
                                                    value={prop.porcentaje}
                                                    text={`${prop.porcentaje}%`}
                                                    strokeWidth={8}
                                                />
                                        </div>
                                    </div>
                                    <div style={{float:"left", width:"300px"}}>
                                        <h6 style={{ textTransform:"lowercase" }}>{entreTot} entregados | {devueTot} entregas fallidas</h6>
                                        <label>{ pendiTot } Pendientes</label>
                                    </div>
                                    <div style={{float:"left"}}>
                                        <h6>{ parseFloat(prop.liquidado) > 0 ? "Cerrado" : "En curso" }</h6>
                                    </div>
                                </div>
                        </CardBody>
                        <CardBody id={`div_${key}`} key = { key } style={{ display:"none", cursor:"pointer" }}>
                        {
                            props.repartosData.resultado.detallerepartos.map((data,index)=>{
                                if(data.unidad == prop.unidad){
                                    return <div key={index} style={{float:"left", width:"100%"}}> 
                                        <div style={{float:"left", width:"200px"}}>
                                            <h6>Ruta #<label style={{ color:"#000" }}>{data.folio}</label></h6>
                                        </div>
                                        <div style={{float:"left", width:"200px"}}>
                                            <h6>Total Guías:&nbsp;<label style={{ color:"#000", fontSize:"14px" }}>{data.totalguias}</label></h6>
                                        </div>
                                        <div style={{float:"left", width:"200px"}}>
                                            <h6>Entregadas:&nbsp;<label style={{ color:"#4caf50", fontSize:"14px" }}>{data.totalentregadas}</label></h6>
                                        </div>
                                        <div style={{float:"left"}}>
                                            <h6>Fallidas:&nbsp;<label style={{ color:"#b20000", fontSize:"14px" }}>{data.totaldevueltas}</label></h6>
                                        </div>
                                    </div>
                                }
                            })
                        }
                        </CardBody>
                    </Card>
                // </div>
            ]
        })
    );

    const handleRutaClick = (id, reparto,unidad) => {
        // mostrarReparto(id, reparto);
        setIdReparto(id);
        setFolioReparto(reparto);
        setUnidad(unidad);
        setmostrarMapa(true);
    }

    const returnToRepartos = () => {
        props.scrollToTop();
        setmostrarMapa(false);
        props.f_ObtenerRepartos(sucursal,fecha);
    }

    const returnToListadoSucursales = () => {        
        props.returnToList();
    }

    const hideAlert = () => { props.closeMessages(); };
    const handleLogOut = () => { props.closeMessages(); props.logout(); };

    let errorMessage = !props.MensajeError ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
          onCancel={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.MensajeError}
        </SweetAlert>
      );

    const form = (!formData) ? ((<Spinner width={"80%"} height={5} color={"black"} loading />)) : <div>
        <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={2}>
                    <Card>
                        <CardBody style={{textAlign:'center'}}>
                            <h6>Rutas</h6>
                            <label style={{fontSize:"22px", color:"#000"}}>{ totalRepartos }</label>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={2}>
                    <Card>
                        <CardBody style={{textAlign:'center'}}>
                            <h6>En curso</h6>
                            <label style={{fontSize:"22px", color:"#000"}}>{ totalEnCurso }</label>
                        </CardBody>
                    </Card>
                </GridItem>
                {/* <GridItem xs={12} sm={6} md={6} lg={2}>
                    <Card>
                        <CardBody>
                            <section className="container">
                                <div style={{float:"left", width:"100px", textAlign:"center"}}>
                                    <h6>En curso</h6>
                                    <label style={{fontSize:"22px", color:"#000"}}>{ totalEnCurso }</label>
                                </div>
                                <div style={{float:"left", borderLeft :"1px solid #ccc", width:"80px"}}>
                                    <div style={{ marginLeft:"15px", width:"85%", height: "85%" }}>
                                        <CircularProgressbar 
                                            value={percentage}
                                            text={`${percentage}%`}
                                            strokeWidth={8}
                                        />
                                    </div>
                                </div>
                                <div style={{float:"left", textAlign:"right"}}>
                                    <div style={{marginLeft:"30px",  textAlign:"right"}}>
                                        <label style={{fontSize:"13px", color:"#000"}}>Demoradas</label>
                                        <label style={{marginLeft:"15px", fontSize:"13px", color:"#000"}}>100%</label><br></br>
                                        <label style={{fontSize:"13px", color:"#000"}}>a Tiempo</label>
                                        <label style={{marginLeft:"15px", fontSize:"13px", color:"#000"}}>100%</label>
                                    </div>
                                </div>
                            </section>
                            
                        </CardBody>
                    </Card>
                </GridItem> */}
                <GridItem xs={12} sm={6} md={6} lg={5}>
                    <Card>
                        <CardBody style={{textAlign:'center'}}>
                            <section className="container">
                                <div style={{float:"left", width:"100px"}}>
                                    <h6>Guías</h6>
                                    <label style={{fontSize:"22px", color:"#000"}}>{ totalGuias }</label>
                                </div>
                                <div style={{float:"left", borderLeft :"1px solid #ccc"}}>
                                    <div style={{marginLeft:"30px",  textAlign:"right"}}>
                                        <Tooltip title={ cantEntregadas } placement="left-start">
                                            <label style={{fontSize:"13px", color:"#000"}}>Entregados</label>
                                        </Tooltip>
                                        <label style={{marginLeft:"15px", fontSize:"13px", color:"#000"}}>{ totalEntregadas }%</label><br></br>
                                        <Tooltip title={ cantDevueltas } placement="left-start">
                                            <label style={{fontSize:"13px", color:"#000"}}>Entregas Fallidas</label>
                                        </Tooltip>
                                        <label style={{marginLeft:"15px", fontSize:"13px", color:"#000"}}>{ totalDevueltas }%</label><br></br>
                                        <Tooltip title={ cantPendientes } placement="left-start">
                                            <label style={{fontSize:"13px", color:"#000"}}>Pendientes</label>
                                        </Tooltip>
                                        <label style={{marginLeft:"15px", fontSize:"13px", color:"#000"}}>{ totalPendientes }%</label>
                                    </div>
                                </div>
                            </section>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={3}>
                    <Card color="warning">
                        <CardBody style={{textAlign:'center'}}>
                            <h6>Guías Almacen Destino(EAD)</h6>
                            <label style={{fontSize:"22px", color:"#FFF"}}>{ totalEnSucursal }</label>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    { 
                        cards.map((prop,key) => {
                            return prop;
                        }) 
                    }
                </GridItem>
            </GridContainer>
            <div style={{ width:"100%",  textAlign:"center" }}>
                <Button
                    color="warning"
                    onClick={returnToListadoSucursales}
                    className={classes.registerButton}
                >
                    Regresar
                </Button>
            </div>
    </div>;

    // idReparto={idReparto} folioReparto={folioReparto}
    const mapa = <MonitoreoMapa unidad={unidad} fecha={fecha} sucursal={sucursal} returnToRepartos={returnToRepartos} scrollToTop={scrollToTop}></MonitoreoMapa>;

    return (
        <React.Fragment>
            { errorMessage }
            { mostrarMapa ? mapa : form }
        </React.Fragment>
      );
}

const mapStateToProps = (state) => {
    return {
        // "listData": state.catalogoCategorias.catalogoCategorias_List,
        // "listData_Error": state.catalogoCategorias.catalogoCategorias_List_Error,
        "repartosData": state.monitoreo.monitoreo_Data,
        "MensajeError": state.monitoreo.monitoreo_Error_Message,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // "getListForTable": () => dispatch(getListForTable()),
        "f_ObtenerRepartos": (sucursal, fecha) => dispatch(f_ObtenerRepartos(sucursal, fecha)),
        "closeMessages": () => dispatch(closeMessages()),
        "logout": () => dispatch(logout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoreoForm);
// export default MonitoreoForm;