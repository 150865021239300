import http from '../../http';

const vRutaGuiasUnidad = 'guiasunidad/';
const vHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

export const LimpiarNombreExcel = () =>{
    return (dispatch, getState) => {
        dispatch({type: "EXCEL_LIMPIAR"});
    }
}

export const LimpiarData = () =>{
    return (dispatch, getState) => {
        dispatch({type: "GUIASUNIDAD_LIST_GUIAS_LIMPIAR_DATA"});
    }
}

export const getDatosBitacora = (bitacora) =>{
    return (dispatch, getState)=>{
        var parameters = JSON.stringify({bitacora});

        http.post(vRutaGuiasUnidad+'datosbitacora.php', parameters, vHeader)
        .then(function (response){
            dispatch({
                type: "DATOSBITACORA_SUCCESS", payload: {
                    data: response.data,
                }
            });
        })
        .catch(function (error) {
            let errorMessage = "";
            if (error.response) {
                errorMessage = error.response.data.mensaje;
              } else if (error.request) {
                errorMessage = "The request was made but no response was received";
              } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage = error.message;
              }

              dispatch({ type: 'DATOSBITACORA_ERROR', payload: { error: errorMessage } });
        })
        .finally(function () {
            //dispatch({ type: 'HIDE_LOADING' });
        });
    }
}

export const getExcelGuias = (bitacora, unidad) => {
    return (dispatch, getState) => {
        var parameters = JSON.stringify({ bitacora, unidad });

        http.post(vRutaGuiasUnidad+'excelGuias.php', parameters, vHeader)
            .then(function (response){
                // console.log(response);
                dispatch({
                    type: "EXCEL_SUCCESS", payload: {
                        data: response.data.nombreexcel,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }
    
                  dispatch({ type: 'EXCEL_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            }
        );
    }
}

export const getGuiasBitacora = (bitacora) => {
    return (dispatch, getState) => {
        var parameters = JSON.stringify({ bitacora });

        http.post(vRutaGuiasUnidad+'obtenerguias.php', parameters, vHeader)
            .then(function (response){
                // console.log(response);
                dispatch({
                    type: "GUIASUNIDAD_LIST_GUIAS_SUCCESS", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    //console.log(error.response.data.mensaje);                    
                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }
    
                  dispatch({ type: 'GUIASUNIDAD_LIST_GUIAS_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            }
        );
    }
}

export const getListForTable = (unidad, fechainicio, fechafin) => {
    return (dispatch, getState) => {
        var parameters = JSON.stringify({ unidad, fechainicio, fechafin });

        // console.log(parameters);

        http.post(vRutaGuiasUnidad+'obtenerlistadodatatable.php', parameters, vHeader)
            .then(function (response){
                // console.log(response);
                dispatch({
                    type: "UNIDADES_LIST_SUCCESS", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    //console.log(error.response.data.mensaje);                    
                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }
    
                  dispatch({ type: 'UNIDADES_LIST_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            }
        );
    }
}

export const getUnidadesForaneas = () => {
    return (dispatch, getState) => {
        
        var parameters = JSON.stringify({ "tiporuta":"FORANEA" });
        //dispatch({ type: 'SHOW_LOADING' });
        
        http.post('catalogos/unidades/listadounidadestiporuta.php', parameters, vHeader)
            .then(function (response){
                // console.log(response);
                dispatch({
                    type: "UNIDADESFORANEAS_LIST_SUCCESS", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    //console.log(error.response.data.mensaje);                    
                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }
    
                  dispatch({ type: 'UNIDADESFORANEAS_LIST_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            });    
    }
}

export const closeMessages = () => {
    return (dispatch, getState) => {
        dispatch({type: "UNIDADESFORANEAS_CLOSE_MESSAGES"});
    }
}