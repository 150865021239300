import React from "react";
import { connect } from "react-redux";
import { login } from "../../store/actions/authActions";
import { verifyLength } from "../../common/functions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";
import Danger from "components/Typography/Danger.js";
import Spinner from "components/Spinner/Spinner.js";
import header_ayax from "assets/img/logo.png";

import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";

const useStyles = makeStyles(styles);

function LoginPage(props) {
  const { loginError, loading } = props;

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  // login form
  const [loginUser, setloginUser] = React.useState(props.user ? props.user : "");
  const [loginUserState, setloginUserState] = React.useState("");
  const [loginPassword, setloginPassword] = React.useState("");
  const [loginPasswordState, setloginPasswordState] = React.useState("");
  
  const handleLoginClick = () => {
    let bFormIsValid = true;
    if (loginUser === "") {
      setloginUserState("error");
      bFormIsValid = false;
    }
    if (loginPassword === "") {
      setloginPasswordState("error");
      bFormIsValid = false;
    }
    if(bFormIsValid){
      props.login({ user: loginUser, password: loginPassword});
    }    
  }

  const errorNotification = loginError ? <Danger>ERROR - {loginError}</Danger>: null;

  return (
    <div className={classes.container}>      
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card>
            <div style={{textAlign:"center"}}>
              <img src={header_ayax} alt="..." style={{ width:"80%", height:"87px", marginTop:"10px" }} />
            </div>
            <CardBody>
              <div style={{textAlign:"center"}}>
                <h3 style={{ color:"#000"}}>Logística</h3>
              </div>
                <CustomInput
                  success={loginUserState === "success"}
                  error={loginUserState === "error"}
                  labelText="Usuario"
                  id="ciLoginUsuario"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: loginUser,
                    onChange: event => {
                      if (verifyLength(event.target.value, 1)) {
                        setloginUserState("success");
                      } else {
                        setloginUserState("error");
                      }
                      setloginUser(event.target.value);
                    },
                    autoComplete: "off"              
                  }}
                >
                </CustomInput>
                <CustomInput
                  success={loginPasswordState === "success"}
                  error={loginPasswordState === "error"}                
                  labelText="Password"
                  id="ciLoginPassword"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                      if (verifyLength(event.target.value, 1)) {
                        setloginPasswordState("success");
                      } else {
                        setloginPasswordState("error");
                      }
                      setloginPassword(event.target.value);
                    },     
                    onKeyDown: event => {
                      if (event.key === 'Enter') {
                        handleLoginClick();
                      }  
                    },                                   
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                {errorNotification}
              </CardBody>
              <Spinner width={"80%"} height={5} color={"black"} loading={loading}/>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="success" onClick={handleLoginClick} simple size="lg" block>
                  ACEPTAR
                </Button>
              </CardFooter>  
                        
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    "user": state.authentication.user,
    "loginError": state.authentication.loginError,
    "loading": state.authentication.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    "login": (credentials) => dispatch(login(credentials)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);