import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { get, closeMessages } from "../store/actions/versionActions";
import { logout, lock } from "../store/actions/authActions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import SnackbarNewVersionDetected from "components/Snackbar/SnackbarNewVersionDetected.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

var ps;

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(stylesAlerts);

function Dashboard(props){
  const { accessToken, isLocked, ...rest } = props;

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(true);
  // const [image, setImage] = React.useState(""); //require("assets/img/sidebar-2.jpg")
  const [image, setImage] = React.useState(null);
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(require("assets/img/logo-white.svg"));
  const [newVersionDetected, setNewVersionDetected] = React.useState(false);
  // styles
  const classes = useStyles();
  const classesAlert = useStylesAlert();

  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount

  const minute = 1000 * 60;

  // gets data when component did mount
  React.useEffect(() => {
      if(accessToken!=null){
        props.get(); 
        setInterval(function(){ props.get(); }, minute * 15); // will run every 20 minutes
      }
  }, []);  

  // if data changes then set to the state the new values
  React.useEffect(() => {
    if(props.versionData){
      setNewVersionDetected(props.versionData.version !== process.env.REACT_APP_VERSION);
    }    
  }, [props.versionData]);

  React.useEffect(() => {
    if(isLocked || !accessToken){
      return;
    }
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  const newVersionMessage = !newVersionDetected ? null : (
    <SweetAlert
      warning
      style={{ display: "block" }}
      title="Nueva versión detectada!"
      onConfirm={() => hideRecargar(true)}
      onCancel={() => hideRecargar(true)}
      confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
    >
      Se ha publicado una nueva versión de la pagina. Favor de hacer click en el boton OK para actualizar.
    </SweetAlert>
  );

  const hideRecargar = () => {
    window.location.reload(true);
  };

  // functions for changeing the states from components
  // const handleImageClick = image => {
  //   setImage(image);
  // };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleBgColorClick = bgColor => {
  //   switch (bgColor) {
  //     case "white":
  //       setLogo(require("assets/img/logo.svg"));
  //       break;
  //     default:
  //       setLogo(require("assets/img/logo-white.svg"));
  //       break;
  //   }
  //   setBgColor(bgColor);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  
  const handleScrollToTop = () => {
    mainPanel.current.scrollTop = 0;
  }

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} scrollToTop={handleScrollToTop}/>}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const refreshPage = () => {
    setNewVersionDetected(false);
    window.location.reload(true);
  }

  if(isLocked) return <Redirect to="/auth/lock-screen-page" />
  if(!accessToken) return <Redirect to="/auth" />      

  const hideAlert = () => { props.closeMessages(); };
  const handleLogOut = () => { props.closeMessages(); props.logout(); };

  let errorMessageToken = !props.MensajeError ? null : (
    <SweetAlert
      danger
      style={{ display: "block" }}
      title="Error!"
      onConfirm={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
      onCancel={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
      confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
    >
      {props.MensajeError}
    </SweetAlert>
  );

  return (    
    <div className={classes.wrapper}>
      {errorMessageToken}
      {newVersionMessage}
      <SnackbarNewVersionDetected
        place="tc"
        color="warning"
        message="Se ha publicado una nueva versión de la pagina. Favor de hacer click en el boton para actualizar."
        open={false}
        closeNotification={refreshPage}
        close
      />
      <Sidebar
        routes={routes}
        logoText={"LOGISTICA PMM"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        /> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return{
    // "isLocked": state.authentication.isLocked,
    "accessToken": state.authentication.accessToken,
    "versionData": state.version.version_Data,
    "MensajeError": state.version.version_Data_Error_Message,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    "get": () => dispatch(get()),
    "logout": () => dispatch(logout()),
    "closeMessages": () => dispatch(closeMessages()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);