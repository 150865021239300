import { combineReducers } from 'redux';
import authReducer from "./authReducer.js";
import monitoreoReducer from './monitoreoReducer.js';
import guiasUnidadReducer from './guiasUnidadReducer.js';
import versionReducer from './versionReducer.js';
import catalogoSucursalReducer from './catalogoSucursalReducer.js';
import gestionPaquetesReducer from './gestionPaquetesReducer.js';
import recoleccionReducer from './recoleccionesReducer.js';
import dashboardReducer from './dashboardReducer.js';

const rootReducer = combineReducers({
    authentication:     authReducer,
    monitoreo:          monitoreoReducer,
    guiasunidad:        guiasUnidadReducer,
    version:            versionReducer,
    catalogosucursal:   catalogoSucursalReducer,
    gestionPaquetes:    gestionPaquetesReducer,
    recolecciones:      recoleccionReducer,
    dashboard:      dashboardReducer,
});

export default rootReducer;