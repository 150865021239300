import React from "react";
import { connect } from "react-redux";

import Datetime from "react-datetime";

import { getUnidadesForaneas, getListForTable, getExcelGuias, 
    LimpiarNombreExcel, LimpiarData, closeMessages } from "../../../store/actions/guiasUnidadActions.js";

import GuiasUnidadDetalle from "./GuiasUnidadDetalle.js";
import { logout, lock } from "../../../store/actions/authActions";

import ReactTable from "react-table";

import { verifyLength } from "../../../common/functions.js";

import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput.js";

import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import Spinner from "components/Spinner/Spinner.js";
// react-select
import Select from "react-select";

import moment from 'moment';
import "moment/locale/es";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

function GuiasUnidad(props){

    const classes = useStyles();
    const [ data, setData ] = React.useState(null);
    const [ unidad, setUnidad ] = React.useState("");
    const [ fechaInicio, setFechaInicio ] = React.useState(new Date());
    const [ fechaFin, setFechaFin ] = React.useState(new Date());
    const [ bitacora, setBitacora ]  = React.useState(0);
    const [ showDetalle, setShowDetalle ]  = React.useState(0);    
    const [ unidadesList, setUnidadesList] = React.useState([]);
    const [ unidadState, setUnidadState] = React.useState("");
    const [ fechaInicioState, setFechaInicioState] = React.useState("");
    const [ fechaFinState, setFechaFinState] = React.useState("");
    const [ showDetalleGuias, setShowDetalleGuias ] = React.useState(false);
    const [ fileSrc, setFileSrc] = React.useState(null);

    const [loading, setLoading] = React.useState(false); 
    const [disabled, setDisabled] = React.useState(false); 

    React.useEffect(() => {
        // props.LimpiarExcel();
        props.LimpiarData();
        props.getUnidadesForaneas();
    }, []);

    // load supervisores list
    React.useEffect(() => {
        setUnidadesList(props.listDataUnidades);
    }, [props.listDataUnidades]);

    // load supervisores list
    React.useEffect(() => {
        setData(props.listData);
        if(props.listData!=null){
            setLoading(false);
        }
    }, [props.listData]);

    React.useEffect(() => {
        if(props.nombreExcel!=null){
            setLoading(false);
            setDisabled(false);
            setFileSrc(process.env.REACT_APP_API_BASE_URL + "guiasunidad/dwnExcel.php?file=" + props.nombreExcel); 
            props.LimpiarExcel();       
        }
    }, [props.nombreExcel]);

    const formattedData = data == null ? [] : (
        data.data.map((prop, key) => {
          return {
            bitacora: prop.bitacora,
            fecha: prop.fecha,
            ruta: prop.ruta,
            conductor: prop.conductor,
            acciones: (
              // we've added some custom button actions
              <div className="actions-right">
                <Button disabled={disabled} color="danger" className={classes.actionButton} onClick={() => {
                  let obj = data.data.find(o => o.bitacora === prop.bitacora);
                  downloadButtonClick(obj.bitacora);
                }}>
                    <CloudDownloadIcon className={classes.icon} />
                </Button>
                {" "}
                <Button color="success" className={classes.actionButton} onClick={() => {
                  let obj = data.data.find(o => o.bitacora === prop.bitacora);
                  editButtonClick(obj.bitacora);
                }}>
                  <VisibilityIcon className={classes.icon} />
                </Button>{" "}            
              </div>
            )
          }
        })
    );

    const downloadButtonClick = (Bitacora) =>{
        setLoading(true);
        setDisabled(true);
        props.getExcelGuias(Bitacora, unidad.label);
    }

    const editButtonClick = (Bitacora) =>{
        setBitacora(Bitacora);
        setShowDetalleGuias(true);
    }

    const buscarClick = () => {
        let bFormIsValid = true;    
        if (unidad === "") {
            setUnidadState("error");
            bFormIsValid = false;
        }

        if (fechaInicio === "") {
            setFechaInicioState("error");
            bFormIsValid = false;
        }
        
        if (fechaFin === "") {
            setFechaFinState("error");
            bFormIsValid = false;
        }

        // if( moment(fechaFin).isBefore(fechaInicio) ){
        //     setFechaFinState("error");
        //     bFormIsValid = false;
        // }

        // moment('2010-10-20').isBefore('2010-12-31', 'year'); // false
        // moment('2010-10-20').isBefore('2011-01-01', 'year'); // true

        if(bFormIsValid){
            setLoading(true);
            let vFechaInicio = moment(fechaInicio).format("YYYY-MM-DD");
            let vFechaFin = moment(fechaFin).format("YYYY-MM-DD");
            // console.log(unidad.label+"//"+vFechaInicio+"//"+vFechaFin);
            props.getListForTable(unidad.label, vFechaInicio, vFechaFin);
        }
    }

    const handleFechaInicioChange = date => {
        setFechaInicio(date);
    };
    
    const handleFechaFinChange = date => {
        setFechaFin(date);
    };
    
    const validDateTimes = (current) => {
        const today = Datetime.moment();
        return current.isBefore(today);
    }

    const handleUnidadesChange = (selectedOption) => {
        // console.log(selectedOption);  
    
        if(selectedOption){
            setUnidadState("success");      
            setUnidad(selectedOption);
        //   props.getGuardiasPorSupervisorList(selectedOption.value);
        }
        else{
            setUnidadState("error");
            setUnidad("");
        }
      }

    const returnToList = () => {        
        setShowDetalleGuias(false);
        // buscarClick();
        props.scrollToTop();   
    }

    const unidadesStyles = {    
        control: styles => ({ ...styles, marginTop: '27px' }),
        placeholder: styles => ({ ...styles, color: unidadState === "error" ? '#f44336' : unidadState === "success" ? '#4caf50' : '#AAAAAA' }),    
    };

    const hideAlert = () => {
        props.closeMessages();
    };

    const handleLogOut = () => {
        props.closeMessages();
        props.logout();
    };

    let errorMessage = !props.MensajeError ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
          onCancel={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.MensajeError}
        </SweetAlert>
      );

    const detalle = <GuiasUnidadDetalle Bitacora={bitacora} returnToList={returnToList} />;

    const spinner = <Spinner width={"80%"} height={5} color={"black"} loading={loading} />;

    const reactTable = <React.Fragment>      
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                        <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <Select 
                                options={unidadesList} 
                                isClearable
                                isDisabled={unidadesList == null}
                                isLoading={unidadesList == null}
                                onChange={handleUnidadesChange}    
                                styles={unidadesStyles}                       
                                placeholder="Seleccionar Unidad"
                                value={unidad}
                            />
                        </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <InputLabel className={classes.label}>Fecha Inicio</InputLabel>
                        <br />
                        <FormControl fullWidth>
                            <Datetime
                                value= {moment(fechaInicio).format("DD/MM/YYYY")}
                                locale="es"
                                closeOnSelect
                                timeFormat={false}
                                inputProps={{ 
                                    placeholder: "Seleccione",    
                                    readOnly: true,                   
                                }}
                                onChange={handleFechaInicioChange}
                                isValidDate={validDateTimes}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                        <InputLabel className={classes.label}>Fecha Fin</InputLabel>
                        <br />
                        <FormControl fullWidth>
                            <Datetime
                                value= {moment(fechaFin).format("DD/MM/YYYY")}
                                locale="es"
                                closeOnSelect
                                timeFormat={false}
                                inputProps={{ 
                                    placeholder: "Seleccione",    
                                    readOnly: true,                   
                                }}
                                onChange={handleFechaFinChange}
                                isValidDate={validDateTimes}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                    <Button
                        color="success"
                        onClick={buscarClick}
                        className={classes.registerButton}
                    >
                        Buscar
                    </Button>
                    </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                    <CardBody>
                    <ReactTable
                    data={formattedData}
                    filterable
                    defaultFilterMethod={(filter, row, column) => {
                    const id = filter.pivotId || filter.id
                    return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                    }
                    }
                    columns={[
                    {
                        Header: "Bitacora",
                        accessor: "bitacora"
                    },
                    {
                        Header: "Fecha",
                        accessor: "fecha"
                    },
                    {
                        Header: "Ruta",
                        accessor: "ruta"
                    },
                    {
                        Header: "Conductor",
                        accessor: "conductor"
                    },
                    {
                        Header: "Acciones",
                        accessor: "acciones",
                        sortable: false,
                        filterable: false
                    }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom
                    className="-striped -highlight"
                    previousText='Anterior'
                    nextText='Siguiente'
                    loadingText='Cargando...'
                    noDataText='No se encontro ningun registro'
                    pageText='Pagina'
                    ofText='de'
                    rowsText='Filas'
                />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
        
        
    </React.Fragment>

    return (
        <React.Fragment>
            { errorMessage }
            <div>{ spinner } </div>
            { showDetalleGuias ? detalle : reactTable }
            <div style={{display: 'none'}}>
                <iframe src={fileSrc} />
            </div>
        </React.Fragment>
    );
}

    const mapStateToProps = (state) => {
        return {
           "listDataUnidades": state.guiasunidad.guiasUnidad_Select_List,
           "listData": state.guiasunidad.guiasUnidad_List,
           "nombreExcel": state.guiasunidad.guiasUnidad_NombreExcel,
           "MensajeError": state.guiasunidad.guiasUnidad_Error_Message,
        }
    }

    const mapDispatchToProps = (dispatch) => {
        return {          
            "getUnidadesForaneas" : () => dispatch(getUnidadesForaneas()),
            "getListForTable" : (unidad,fechainicio,fechafin) => dispatch(getListForTable(unidad,fechainicio,fechafin)),
            "getExcelGuias" : (bitacora,unidad) => dispatch(getExcelGuias(bitacora,unidad)),
            "LimpiarExcel": () => dispatch(LimpiarNombreExcel()),
            "LimpiarData": () => dispatch(LimpiarData()),
            "closeMessages": () => dispatch(closeMessages()),
            "logout": () => dispatch(logout()),
        }
    }
export default connect(mapStateToProps, mapDispatchToProps)(GuiasUnidad);