import React from 'react';
import { connect } from "react-redux";

import { getListForTable, getExcelGuias, LimpiarNombreExcel, LimpiarData } from "../../../store/actions/gestionPaquetesActions";
import { getSucursales, closeMessages } from "../../../store/actions/catalogoSucursalActions";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import GetAppIcon from '@material-ui/icons/GetApp';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button.js";
import Table from "components/Table/Table.js";
import Select from "react-select";

import Spinner from "components/Spinner/Spinner.js";
import Tooltip from '@material-ui/core/Tooltip';

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
const useStyles = makeStyles(styles);

var vTotalGuias = 0;

function GestionPaquetes(props){
    // const [ totalGuias, setTotalGuias] = React.useState(vTotalGuias);
    const [ sucursalesList, setSucursalesList] = React.useState([]);
    const [ sucursalState, setSucursalState] = React.useState("");
    const [ sucursal, setSucursal] = React.useState("");
    const [ data, setData ] = React.useState(null);

    const [loading, setLoading] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [ fileSrc, setFileSrc] = React.useState(null);

    const [ idSucursalLogueada, setIdSucursalLogueada] = React.useState(localStorage.getItem('sucursal'));
    const [ sucursalLogueada, setSucursalLogueada] = React.useState(localStorage.getItem('nombresucursal'));
    const [ disabledSucursal, setDisabledSucursal] = React.useState(false);

    const [ buttonVisible, setButtonVisible ] = React.useState(true);

    const classes = useStyles(); 
    

    React.useEffect(() => {
        props.LimpiarData();
        props.LimpiarExcel();        
        props.getSucursales();
    }, []);

    // load supervisores list
    React.useEffect(() => {
        setSucursalesList(props.listDataSucursales);
        if(idSucursalLogueada>1){
            setLoading(true);
            setButtonVisible(false);
            setSucursal({value:idSucursalLogueada,label:sucursalLogueada});
            props.getListForTable(idSucursalLogueada);
        }
    }, [props.listDataSucursales]);

    React.useEffect(() => {
        if(props.nombreExcel!=null){
            setLoading(false);
            setDisabled(false);
            setFileSrc(process.env.REACT_APP_API_BASE_URL + "gestionpaquetes/dwnExcel.php?file=" + props.nombreExcel); 
            props.LimpiarExcel();       
        }
    }, [props.nombreExcel]);

    // Data para tabla
    React.useEffect(() => {
        setData(props.listData);
        setLoading(false);
    }, [props.listData]);

    const totalGuias = data == null ? 0 : data.totales.izq;
    const totalGuiasReparto = data == null ? 0 : data.totales.der;

    const formattedData = data == null ? null : (        
        data.izq.map((prop, key) => {
            let color = parseInt(prop.total) > 0 ? { cursor:'pointer', color:"#4caf50" } : { cursor:'', color:"#000" };    
            return [
                prop.titulo,
                prop.total,
                <Tooltip title="Descargar Excel" placement="top">
                <GetAppIcon disabled={disabled} key={prop.key} style={color} onClick={() => {
                    // let obj = data.data.find(o => o.bitacora === prop.bitacora);
                    if(parseInt(prop.total) > 0){
                        downloadExcel(prop.key);
                    }
                }}/>
                </Tooltip>
            ]
        })
    );
    
    const formattedDataReparto = data == null ? null : (        
        data.der.map((prop, key) => {
            let color = parseInt(prop.total) > 0 ? { cursor:'pointer', color:"#4caf50" } : { cursor:'', color:"#000" };

            return [
                prop.titulo,
                prop.total,
                <Tooltip title="Descargar Excel" placement="top">
                <GetAppIcon disabled={disabled} key={prop.key} style={color} onClick={() => {
                    // let obj = data.data.find(o => o.bitacora === prop.bitacora);
                    if(parseInt(prop.total) > 0){
                        downloadExcel(prop.key);
                    }
                }}/>
                </Tooltip>
            ]
        })
    );

    const downloadExcel = (tipo) =>{
        setLoading(true);
        setDisabled(true);
        props.getExcelGuias(tipo, sucursal.value);
    };

    const handleSucursalChange = (selectedOption) => {
        if(selectedOption){
            setSucursalState("success");
            setSucursal(selectedOption);
        }else{
            setSucursalState("error");
            setSucursal("");
        }
    }

    const sucursalesStyles = {    
        control: styles => ({ ...styles, marginTop: '27px' }),
        placeholder: styles => ({ ...styles, color: sucursalState === "error" ? '#f44336' : sucursalState === "success" ? '#4caf50' : '#AAAAAA' }),    
    };

    const buscarClick = () => {
        let bFormIsValid = true;    
        if (sucursal === ""){
            setSucursalState("error");
            bFormIsValid = false;
        }
        
        if(bFormIsValid){
            setLoading(true);           
            props.getListForTable(sucursal.value);
        }
    }

    // const hideAlert = () => { props.closeMessages(); };
    // const handleLogOut = () => { props.closeMessages(); props.logout(); };

    // let errorMessage = !props.MensajeError ? null : (
    //     <SweetAlert
    //       danger
    //       style={{ display: "block" }}
    //       title="Error!"
    //       onConfirm={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
    //       onCancel={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
    //       confirmBtnCssClass={classes.button + " " + classes.success}
    //     >
    //       {props.MensajeError}
    //     </SweetAlert>
    //   );
      const spinner = <Spinner width={"80%"} height={5} color={"black"} loading={loading} />;

      const container = <GridContainer>
            {
               buttonVisible ?  <GridItem xs={12} sm={12} md={12}>
               <Card>
                   <CardBody>
                       <GridContainer>
                           <GridItem xs={12} sm={12} md={4}>
                               <Select 
                                   options={sucursalesList} 
                                   isClearable
                                   isDisabled={ (idSucursalLogueada>1) ? true : sucursalesList == null}
                                   isLoading={sucursalesList == null}
                                   onChange={handleSucursalChange}    
                                   styles={sucursalesStyles}                       
                                   placeholder="Seleccionar Sucursal"
                                   value={sucursal}
                               />
                           </GridItem>
                           <GridItem xs={12} sm={12} md={3}>
                           <br />                               
                                      <Button
                                       visible={buttonVisible}
                                       color="success"
                                       onClick={buscarClick}
                                       className={classes.registerButton}
                                   >
                                       Buscar
                                   </Button>
                           </GridItem>
                       </GridContainer>
                   </CardBody>
               </Card>
           </GridItem> : ""
            }
            
            
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                <CardBody>
                    <div className={classes.cardHeader}>
                    <h4 className={classes.cardTitle}>En sucursal <label>( {totalGuias} Guías )</label></h4>
                    </div>
                    <br />
                    <Table
                        tableData = { 
                            formattedData === null ? 
                            [[
                                "Almacen Destino EAD",
                                0,
                                <GetAppIcon/>
                            ],[
                                "Almacen Destino OCU",
                                0,
                                <GetAppIcon/>
                            ],[
                                "Almacen Trasbordo",
                                0,
                                <GetAppIcon/>
                            ],[
                                "Almacen Origen (Registradas)",
                                0,
                                <GetAppIcon/>
                            ],[
                                "Por Recibir",
                                0,
                                <GetAppIcon/>
                            ],[
                                "En Transito",
                                0,
                                <GetAppIcon/>
                            ],[
                                "Almacen Origen (Por Recibir)",
                                0,
                                <GetAppIcon/>
                            ]] : formattedData                
                        }
                        />
                    
                </CardBody>
                </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <Card>
                <CardBody>
                    <div>
                    <div className={classes.cardHeader}>
                        <h4 className={classes.cardTitle}>En Reparto <label>( {totalGuiasReparto} Guías )</label></h4>
                    </div>
                    <br />              
                    <Table
                        tableData={
                            formattedDataReparto === null ? 
                            [[
                            "En reparto EAD",
                            "0",
                            <GetAppIcon />
                            ]] : formattedDataReparto
                        }
                        />             
                    </div>
                </CardBody>
                </Card>
            </GridItem>
            
    </GridContainer>;

    return (
        <React.Fragment>
            <div>{ spinner } </div>
            { container }
            <div style={{display: 'none'}}>
                <iframe src={fileSrc} />
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        "listData": state.gestionPaquetes.gestionPaquetes_Data,
        "nombreExcel": state.gestionPaquetes.gestionPaquetes_NombreExcel,
        "listDataSucursales": state.catalogosucursal.catalogoSucursal_List,
        "MensajeError": state.catalogosucursal.catalogoSucursal_Error,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        "closeMessages": () => dispatch(closeMessages()),
        "getSucursales": () => dispatch(getSucursales()),
        "getListForTable" : (sucursal) => dispatch(getListForTable(sucursal)),
        "getExcelGuias" : (tipo,sucursal) => dispatch(getExcelGuias(tipo,sucursal)),
        "LimpiarExcel": () => dispatch(LimpiarNombreExcel()),
        "LimpiarData": () => dispatch(LimpiarData()),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(GestionPaquetes);