const initState = {
    gestionPaquetes_Data: null,
    gestionPaquetes_Error: null,
    gestionPaquetes_NombreExcel:null,
    loading: null
}

const gestionPaquetesReducer = (state = initState, action) => {
    switch (action.type) {
        case "GESTION_PAQUETES_SUCCESS":
            return {
                ...state,
                gestionPaquetes_Data:action.payload.data,
                gestionPaquetes_Error:null
            }
        case "GESTION_PAQUETES_ERROR":
            return {
                ...state,
                gestionPaquetes_Data:null,
                gestionPaquetes_Error:action.payload.error
            }
        case "GESTION_PAQUETES_EXCEL":
            return {
                ...state,
                gestionPaquetes_NombreExcel:action.payload.data,
                gestionPaquetes_Error:null
            }
        case "GESTION_PAQUETES_EXCEL_LIMPIAR":
            return{
                ...state,
                gestionPaquetes_NombreExcel:null,
            }
        case "GESTION_PAQUETES_LIMPIAR_DATA":
            return{
                ...state,
                gestionPaquetes_Data:null,
            }
        default:
            return state;
    }
};


export default gestionPaquetesReducer;