import http from '../../http';

const vRutaMonitoreo = 'monitoreo/';
const vHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

export const getListForTable = (fecha,sucursal) => {
    return (dispatch, getState) => {
        
        var parameters = JSON.stringify({ fecha, sucursal });
        // console.log(parameters);
        //dispatch({ type: 'SHOW_LOADING' });
        
        http.post(vRutaMonitoreo+'obtenerlistadodatatable.php', parameters, vHeader)
            .then(function (response) {
                dispatch({
                    type: "MONITOREO_LIST_SUCCESS", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    //console.log(error.response.data.mensaje);                    
                    //console.log(error.response.status);
                    //console.log(error.response.headers);
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }
    
                  dispatch({ type: 'MONITOREO_LIST_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            });    
    }
}

export const f_ObtenerRepartos = (sucursal,fecha) => {
    return (dispatch, getState) => {

        dispatch({ type: 'SHOW_LOADING' });

        var parameters = JSON.stringify({ sucursal, fecha });

        // console.log(parameters);

        http.post(vRutaMonitoreo+'monitoreo.php', parameters, vHeader)
            .then(function (response) {
                // console.log(response);
                dispatch({
                    type: "MONITOREO_OBTENER_REPARTOS", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }   

                  dispatch({ type: 'MONITOREO_LIST_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            }
        );
    }
}
// idreparto,folioreparto,guia
export const f_ObtenerReparto = (unidad,sucursal,fecha,guia) => {
    return (dispatch, getState) => {

        dispatch({ type: 'SHOW_LOADING' });

        var parameters = JSON.stringify({ unidad,sucursal,fecha,guia });

        // console.log(parameters);

        http.post(vRutaMonitoreo+'reparto.php', parameters, vHeader)
            .then(function (response) {
                // console.log(response);
                dispatch({
                    type: "MONITOREO_OBTENER_REPARTO", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                // console.log(error);
                let errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }   

                  dispatch({ type: 'MONITOREO_LIST_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            }
        );
    }
}

export const ObtenerInformacionGuia = (guia) => {
    return (dispatch, getState) => {

        dispatch({ type: 'SHOW_LOADING' });

        var parameters = JSON.stringify({ guia });

        http.post(vRutaMonitoreo+'guia.php', parameters, vHeader)
            .then(function (response) {
                // console.log(response);
            
                dispatch({
                    type: "MONITOREO_OBTENER_GUIA", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }   

                  dispatch({ type: 'MONITOREO_LIST_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            }
        );
    }
}

export const f_LimpiarReparto = () => {
    return (dispatch, getState) => {
        dispatch({type: "MONITOREO_LIMPIAR_REPARTO"});
    }
}

export const closeMessages = () => {
    return (dispatch, getState) => {
        dispatch({type: "MONITOREO_CLOSE_MESSAGES"});
    }
}