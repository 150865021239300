// constant to encrypt and decrypt data
  // const Cryptr = require('cryptr');
  // export const cryptr = new Cryptr('&jewofWsE@#%$rtfdseIfgrdsaf');

// function that verifies if a string has a given length or not
  export const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  // function that verifies if the user has access to a determine module
  export const hasAccess = (nodeName) => {      
    const permissions = JSON.parse(localStorage.getItem('permisos'));
    var result = false;
    
    if(permissions){   
      result = permissions.filter(row => row.nombreitem === nodeName && row.acceso === "1").length > 0;
    }
    
    return result;
  };

  // function that verifies if the complete section should be hidden
  export const showCollapse = (menuName) => {      
    const permissions = JSON.parse(localStorage.getItem('permisos'));
    var result = false;
    
    if(permissions){   
      result = permissions.filter(row => row.menu === menuName && row.acceso === "1").length > 0;
    }
    
    return result;
  };
  
  // function that returns true if value is email, false otherwise
  export const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  // function that verifies if value contains only numbers
  export const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };  

  // function that verifies if value contains only numbers
  export const verifyDecimalNumber = value => {
    var numberRex = new RegExp("^[0-9]+(\.[0-9]{1,2})?$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };  