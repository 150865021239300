import React from "react";
import { connect } from "react-redux";

import { getDatosBitacora, getGuiasBitacora, closeMessages } from "../../../store/actions/guiasUnidadActions.js";
import { logout, lock } from "../../../store/actions/authActions";

import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CardIcon from "components/Card/CardIcon.js";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

function GuiasUnidadDetalle(props){
    const classes = useStyles();
    const [ data, setData ] = React.useState(null);
    const [ bitacora, setBitacora ] = React.useState(props.Bitacora);

    const [ fechaBitacora, setFechaBitacora ] = React.useState("");
    const [ unidad, setUnidad ] = React.useState("");
    const [ remolque, setRemolque ] = React.useState("");
    const [ conductor, setConductor ] = React.useState("");
    const [ ruta, setRuta ] = React.useState("");


    // load supervisores list
    React.useEffect(() => {
        props.getDatosBitacora(bitacora);
        props.getGuiasBitacora(bitacora);
    }, []);

    // // load supervisores list
    React.useEffect(() => {
        const formData = props.datosBitacora;    
        if(formData){
            // console.log(formData);
            setFechaBitacora(formData.resultado.fecha);
            setRuta(formData.resultado.ruta);
            setConductor(formData.resultado.conductor);
            setUnidad(formData.resultado.unidad);
            setRemolque(formData.resultado.remolque);
        }
    }, [props.datosBitacora]);

    React.useEffect(() => {
        setData(props.listDataGuias)
    }, [props.listDataGuias]);
    

    const formattedData = data == null ? [] : (
        data.data.map((prop, key) => {
          return {
            guia: prop.guia,
            fecha: prop.fecha,
            estado: prop.estado,
            origen: prop.origen,
            destino: prop.destino,
            remitente: prop.remitente,
            destinatario: prop.destinatario,
            cantidad: prop.cantidad,
            descripcion: prop.descripcion,
            contenido: prop.contenido            
          }
        })
    );

    const returnClick = () =>{
        props.returnToList();
    }

    const editButtonClick = (Bitacora) =>{
        setBitacora(Bitacora);
    }

    const hideAlert = () => {
        props.closeMessages();
    };

    const handleLogOut = () => {
        props.closeMessages();
        props.logout();
    };

    let errorMessage = !props.MensajeError ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
          onCancel={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.MensajeError}
        </SweetAlert>
      );

    const form = props.datosBitacora == null ? (<React.Fragment>
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardBody>
                        <h2>Cargando informacion...</h2>
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    </React.Fragment>) : (
        <React.Fragment>
            <Card>
                    <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                                labelText="Folio Bitacora"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    autoComplete: "off",
                                    value:bitacora,
                                    
                                }}
                            >
                            </CustomInput>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                                labelText="Fecha Bitacora"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    autoComplete: "off",
                                    value:fechaBitacora
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                                labelText="Unidad"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    autoComplete: "off",
                                    value:unidad
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                                labelText="Remolque"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    autoComplete: "off",
                                    value:remolque || ""
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Conductor"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    autoComplete: "off",
                                    value:conductor,
                                    
                                }}
                            >
                            </CustomInput>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText="Ruta"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    autoComplete: "off",
                                    value:ruta,
                                }}
                            >
                            </CustomInput>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        </React.Fragment>
    );

    const reactTable = <React.Fragment>      
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                
                <CardHeader color="green" icon>
                    <CardIcon color="green">
                    <CloudDownloadIcon />
                    </CardIcon>
                    <h4 className={classes.cardIconTitle}>Guias en unidad</h4>
                </CardHeader>

                    <CardBody>
                    <ReactTable
                        data={formattedData}
                        filterable
                        defaultFilterMethod={(filter, row, column) => {
                        const id = filter.pivotId || filter.id
                        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                        }
                        }
                        columns={[
                        {
                            Header: "Guia",
                            accessor: "guia"
                        },
                        {
                            Header: "Fecha",
                            accessor: "fecha"
                        },
                        {
                            Header: "Estado",
                            accessor: "estado"
                        },
                        {
                            Header: "Origen",
                            accessor: "origen"
                        },
                        {
                            Header: "Destino",
                            accessor: "destino"
                        },
                        {
                            Header: "Remitente",
                            accessor: "remitente"
                        },
                        {
                            Header: "Destinatario",
                            accessor: "destinatario"
                        },
                        {
                            Header: "Cantidad",
                            accessor: "cantidad"
                        },
                        {
                            Header: "Descripcion",
                            accessor: "descripcion"
                        },
                        {
                            Header: "Contenido",
                            accessor: "contenido"
                        },
                        
                        ]}
                        defaultPageSize={10}
                        showPaginationTop={false}
                        showPaginationBottom
                        className="-striped -highlight"
                        previousText='Anterior'
                        nextText='Siguiente'
                        loadingText='Cargando...'
                        noDataText='No se encontro ningun registro'
                        pageText='Pagina'
                        ofText='de'
                        rowsText='Filas'
                    />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
        <Button
            color="warning"
            onClick={returnClick}
            className={classes.registerButton}
        >
            Regresar
        </Button>
        
    </React.Fragment>

    return (
        <React.Fragment>
            { errorMessage }
            { form }
            { reactTable }
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
       "listDataGuias": state.guiasunidad.guiasUnidad_List_Guias,
       "datosBitacora": state.guiasunidad.guiasUnidad_Data,
       "MensajeError": state.guiasunidad.guiasUnidad_Error_Message,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {        
        "getDatosBitacora" : (bitacora) => dispatch(getDatosBitacora(bitacora)),
        "getGuiasBitacora" : (bitacora) => dispatch(getGuiasBitacora(bitacora)),
        "closeMessages": () => dispatch(closeMessages()),
        "logout": () => dispatch(logout()),       
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GuiasUnidadDetalle);