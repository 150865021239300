import React from "react";
import { connect } from "react-redux";

import ReactTable from "react-table";
import { getListForTable, closeMessages } from "../../../store/actions/monitoreoActions";
import { logout, lock } from "../../../store/actions/authActions";

import MonitoreoForm from "./MonitoreoForm.js";

import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import moment from 'moment';
import "moment/locale/es";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from '@material-ui/icons/Visibility';

// import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import stylesSweetAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);
const useStylesAlert = makeStyles(stylesSweetAlert);

function Monitoreo(props) {
    const [ data, setData ] = React.useState(null);
    const [ showList, setShowList ] = React.useState(true);
    const [ sucursal, setSucursal ] = React.useState(0);
    const [ fecha, setFecha ] = React.useState(new Date());

    const [ sucursalLogueada, setSucursalLogueada] = React.useState(localStorage.getItem('sucursal'));

    const classes = useStyles();
    const classesAlert = useStylesAlert();
    
    // gets data when component did mount
    React.useEffect(() => {
        let vFecha = moment(fecha).format("YYYY-MM-DD");
        props.getListForTable(vFecha, sucursalLogueada);
    }, []);

    // if data changes then set to the state the new values
    React.useEffect(() => {
        setData(props.listData);
    }, [props.listData]);
    
    const formattedData = data == null ? null : (
        data.data.map((prop, key) => {
          return {
            id: prop.id,
            sucursal: prop.sucursal,
            fecha: prop.fecha,
            rutas: prop.rutas,
            acciones: (
              // we've added some custom button actions
              <div className="actions-right">
                <Button color="success" className={classes.actionButton} onClick={() => {
                  let obj = data.data.find(o => o.id === prop.id);
                  editButtonClick(obj.id,obj.fecha);
                }}>
                  <VisibilityIcon className={classes.icon} />
                </Button>{" "}            
              </div>
            )
          }
        })
      );

      const editButtonClick = (id,fecha) => {
        setSucursal(id);
        setFecha(changeDate(fecha));
        // setFecha('2020-06-15');
        setShowList(false);    
      }

      const returnToList = () => {
        props.getListForTable(fecha);
        setShowList(true); 
        props.scrollToTop();   
      }

      const scrollToTop = () => {
        props.scrollToTop(); 
      }

    function changeDate(fecha){
        let obj = fecha.split("/");
        return obj[2]+"-"+obj[1]+"-"+obj[0];
    }

    const handleFechaChange = date => {
      let vFecha = moment(date).format("YYYY-MM-DD");
      setFecha(date);
      props.getListForTable(vFecha,sucursalLogueada);
    };

    const validDateTimes = (current) => {
      const today = Datetime.moment();
      return current.isBefore(today);
    }

    const hideAlert = () => { props.closeMessages(); };
    const handleLogOut = () => { props.closeMessages(); props.logout(); };

    let errorMessage = !props.MensajeError ? null : (
        <SweetAlert
          danger
          style={{ display: "block" }}
          title="Error!"
          onConfirm={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
          onCancel={() => props.MensajeError.indexOf("Acceso denegado")>-1 ? handleLogOut() : hideAlert()}
          confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
        >
          {props.MensajeError}
        </SweetAlert>
      );

    // mostrarReparto={mostrarReparto}
    const form = <MonitoreoForm Sucursal={sucursal} Fecha={fecha} returnToList={returnToList} scrollToTop={scrollToTop}></MonitoreoForm>;
    
    const reactTable = formattedData == null ? 
  (
    <React.Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <h2>Cargando informacion...</h2>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>    
  ) : (
    <React.Fragment>      
      <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
              <Card>
            <CardBody>
              <GridItem xs={12} sm={12} md={4}>
                <InputLabel className={classes.label}>Fecha Reparto</InputLabel>
                  <br />
                  <FormControl fullWidth>
                      <Datetime
                        // value= {moment(fechaAlta).format("DD/MM/YYYY")}
                        locale="es"
                        closeOnSelect
                        timeFormat={false}
                        inputProps={{ 
                            placeholder: "Seleccione",    
                            readOnly: true,                   
                        }}
                        onChange={handleFechaChange}
                        isValidDate={validDateTimes}
                      />
                  </FormControl>
                  </GridItem>
                  </CardBody>
      </Card>                                              
              </GridItem>
            
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <ReactTable
                data={formattedData}
                filterable
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id
                  return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                }
                }
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                    show: false,
                  },
                  {
                    Header: "Sucursal",
                    accessor: "sucursal"
                  },
                  {
                    Header: "Fecha",
                    accessor: "fecha"
                  },                  
                  {
                    Header: "Unidades en Rutas",
                    accessor: "rutas"
                  },                  
                  {
                    Header: "Acciones",
                    accessor: "acciones",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={20}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                previousText='Anterior'
                nextText='Siguiente'
                loadingText='Cargando...'
                noDataText='No se encontro ningun registro'
                pageText='Pagina'
                ofText='de'
                rowsText='Filas'
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );


    return (
        <React.Fragment>
            { errorMessage }
            { showList ? reactTable : form }
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        "listData": state.monitoreo.monitoreo_List,
        "listData_Error": state.monitoreo.monitoreo_List_Error,
        // "catalogoCategoriasData": state.catalogoCategorias.catalogoCategorias_Data,
        "MensajeError": state.monitoreo.monitoreo_Error_Message,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        "getListForTable": (fecha,sucursal) => dispatch(getListForTable(fecha,sucursal)),
        "closeMessages": () => dispatch(closeMessages()),
        "logout": () => dispatch(logout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Monitoreo);