const initState = {
    loginError: null,
    unlockError: null,
    accessToken: localStorage.getItem("accessToken"),
    userId: localStorage.getItem("userId"),
    user: localStorage.getItem("loginUser"),    
    userFullName: localStorage.getItem("loginUserFullName"), 
    isLocked: localStorage.getItem("isLocked"),    
    permisos: localStorage.getItem("permisos"),
    sucursal: localStorage.getItem("sucursal"),
    nombresucursal: localStorage.getItem("nombresucursal"),
    esmatriz: localStorage.getItem("esmatriz"),
    loading: null,    
    avatar: localStorage.getItem("avatar"),    
}

const authReducer = (state = initState, action) => {
    
    switch (action.type) {
        case "CAMBIO_SUCURSAL":
            localStorage.setItem("sucursal", action.payload.sucursal);            
            localStorage.setItem("nombresucursal", action.payload.nombresucursal); 
            return {
                ...state,               
                sucursal: action.payload.sucursal,
                nombresucursal: action.payload.nombresucursal                
            }
        break;
        case "LOGIN_SUCCESS":
            localStorage.setItem("accessToken", action.payload.accessToken);
            localStorage.setItem("userId", action.payload.userId);
            localStorage.setItem("loginUser", action.payload.user);            
            localStorage.setItem("loginUserFullName", action.payload.userFullName);            
            localStorage.setItem("sucursal", action.payload.sucursal);            
            localStorage.setItem("nombresucursal", action.payload.nombresucursal);            
            localStorage.setItem("esmatriz", action.payload.sucursal==1 ? 's':'n');            
            // localStorage.setItem("avatar", action.payload.avatar);            
            // localStorage.removeItem('isLocked');
            // localStorage.setItem("permisos", action.payload.permisos);   

            return {
                ...state,
                loginError: null,
                unlockError: null,
                accessToken: action.payload.accessToken,
                userId: action.payload.userId,
                user: action.payload.user,                        
                userFullName: action.payload.userFullName,
                sucursal: action.payload.sucursal,
                nombresucursal: action.payload.nombresucursal,
                esmatriz: action.payload.sucursal==1 ? 's':'n',
                isLocked: null,
                // permisos: action.payload.permisos,
                loading: false,
                // avatar: action.payload.avatar,
            }
        case "LOGIN_ERROR":
            return {
                ...state,
                loginError: action.payload.error,
                unlockError: null,
                accessToken: null,
                userId: null,
                userFullName: null,
                permisos: null,
                loading: false,
                avatar:null,
                sucursal:null,
                nombresucursal:null,
                esmatriz:null
            }
        case 'LOGOUT_SUCCESS':
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userId");
            localStorage.removeItem("permisos");
            localStorage.removeItem("sucursal");
            localStorage.removeItem("nombresucursal");
            localStorage.removeItem("esmatriz");
            return {
                ...state,
                loginError: null,
                unlockError: null,
                accessToken: null,
                userId: null,
                userFullName: null,
                permisos: null,
                loading: false,
                sucursal:null,
                nombresucursal:null,
                esmatriz:null
            }
        case 'LOCK_SUCCESS':
            localStorage.removeItem('accessToken');
            localStorage.setItem('isLocked', 'true');
            localStorage.removeItem("permisos");
            return {
                ...state,
                accessToken: null,
                isLocked: 'true',
                permisos: null,
                loading: false,
            }
        case 'UNLOCK_SUCCESS':
                localStorage.setItem("accessToken", action.payload.accessToken);
                localStorage.removeItem('isLocked');
                localStorage.setItem("permisos", action.payload.permisos);  
                localStorage.setItem("avatar", action.payload.avatar);  
                
                return {
                    ...state,
                    loginError: null,
                    unlockError: null,
                    accessToken: action.payload.accessToken,
                    isLocked: null,
                    permisos: action.payload.permisos,
                    avatar: action.payload.avatar,
                    loading: false,
                }
        case 'UNLOCK_ERROR':
            return {
                ...state,
                loginError: null,
                unlockError: action.payload.error,
                loading: false,
            }  
        case 'LOGIN_LOADING':
            return {
                ...state,
                loading: true,            
            }                  
        default:
            return state;
    }
}

export default authReducer;