const initState = {
    recoleccion_List: null,
    recoleccion_List_Recolecciones: null,
    recoleccion_Totales_Recolecciones: null,
    recoleccion_Error: null,
    loading: null
}

const recoleccionReducer = (state = initState, action) => {
    switch(action.type){
        case "RECOLECCION_LIST_SUCCESS":
            return{
                ...state,
                recoleccion_List: action.payload.data,
                recoleccion_Error: null,
            }
        case "RECOLECCION_RECOLECCIONES_LIST_SUCCESS":
            return{
                ...state,
                recoleccion_List_Recolecciones: action.payload.data,
                recoleccion_Error: null,
            }
        case "RECOLECCION_ERROR":
            return{
                ...state,
                recoleccion_Error: action.payload.error,
                recoleccion_List: null,
            }
        case "RECOLECCION_RECOLECCIONES_LIMPIAR_DATA":
            return{
                ...state,
                recoleccion_List_Recolecciones: null,
                recoleccion_Error: null,
            }
        case "RECOLECCION_TOTALES_SUCCESS":
            return{
                ...state,
                recoleccion_Totales_Recolecciones: action.payload.data,
                recoleccion_Error: null,
            }
        default:
            return{
                ...state
            }
    }
}

export default recoleccionReducer;