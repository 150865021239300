const initState = {
    guiasUnidad_List_Guias: null,
    guiasUnidad_List_Guias_Error: null,   
    guiasUnidad_List: null,
    guiasUnidad_List_Error: null,   
    guiasUnidad_Success_Message: null, 
    guiasUnidad_Error_Message: null, 
    guiasUnidad_Data: null,
    guiasUnidad_Data_Error: null,
    guiasUnidad_Select_List:null,
    guiasUnidad_Select_Error: null,
    guiasUnidad_NombreExcel:null,
    guiasUnidad_NombreExcel_Error: null,
    loading: null
}

const guiasUnidadReducer = (state = initState, action) => {
    switch (action.type) {
        case "UNIDADESFORANEAS_CLOSE_MESSAGES":
            return {
                ...state,
                guiasUnidad_Error_Message:null
            }
        case "DATOSBITACORA_SUCCESS":
            return {
                ...state,
                guiasUnidad_Data:action.payload.data,
                guiasUnidad_Error_Message:null
            }
        case "DATOSBITACORA_ERROR":
            return {
                ...state,
                guiasUnidad_Data:null,
                guiasUnidad_Error_Message:action.payload.error
            }
        case "EXCEL_SUCCESS":
            // console.log(action.payload.data);
            return {
                ...state,
                guiasUnidad_NombreExcel:action.payload.data,
                guiasUnidad_Error_Message:null
            }
        case "EXCEL_SUCCESS":
            return {
                ...state,
                guiasUnidad_NombreExcel:null,
                guiasUnidad_Error_Message: action.payload.error,
            }
        case "EXCEL_LIMPIAR":
            return {
                ...state,
                guiasUnidad_NombreExcel:null,
                guiasUnidad_Error_Message: null,
            }
        case "GUIASUNIDAD_LIST_GUIAS_LIMPIAR_DATA":
            return{
                ...state,
                guiasUnidad_List: null,
                guiasUnidad_Error_Message: null,
            }
        case "GUIASUNIDAD_LIST_GUIAS_SUCCESS":
            return{
                ...state,
                guiasUnidad_List_Guias: action.payload.data,
                guiasUnidad_Error_Message: null,
            }
        case "GUIASUNIDAD_LIST_GUIAS_ERROR":
            return{
                ...state,
                guiasUnidad_List_Guias: null,
                guiasUnidad_Error_Message: action.payload.error,
            }
        case "UNIDADES_LIST_SUCCESS":
            return {
                ...state,
                guiasUnidad_List: action.payload.data,
                guiasUnidad_Error_Message: null,
            }
        case "UNIDADES_LIST_ERROR":
            return {
                ...state,
                guiasUnidad_List: null,
                guiasUnidad_Error_Message: action.payload.error,
            }
        case "UNIDADESFORANEAS_LIST_SUCCESS":
            return {
                ...state,
                guiasUnidad_Select_List: action.payload.data,
                guiasUnidad_Error_Message: null,
            }
        case "UNIDADESFORANEAS_LIST_ERROR":
            return {
                ...state,
                guiasUnidad_Select_List: null,
                guiasUnidad_Error_Message: action.payload.error
            }
        default:
            return state;
    }
};


export default guiasUnidadReducer;