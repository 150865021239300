import React from "react";

import { connect } from "react-redux";

// import { ObtenerInformacionGuia } from ""  "../../../store/actions/monitoreoActions";

import { ObtenerInformacionGuia } from "store/actions/monitoreoActions";

import { makeStyles } from "@material-ui/core/styles";

import imageFirma from "assets/img/firma.jpg";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";

// style for this view
// import styles from "assets/jss/material-dashboard-pro-react/customviews/formsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);

function DetalleEnvio(props){
    const [guia, setGuia ] = React.useState(props.Guia);
    // REMITENTE
    const [remitenteClave, setRemitenteClave] = React.useState("");
    const [remitenteNombre, setRemitenteNombre] = React.useState("");
    const [remitenteTelefono, setRemitenteTelefono] = React.useState("");
    const [remitenteCalle, setRemitenteCalle] = React.useState("");
    const [remitenteNumeroExterior, setRemitenteNumeroExterior] = React.useState("");
    const [remitenteCP, setRemitenteCP] = React.useState("");
    const [remitenteColonia, setRemitenteColonia] = React.useState("");
    const [remitenteMunicipio, setRemitenteMunicipio] = React.useState("MZATLAN");
    const [remitenteEstado, setRemitenteEstado] = React.useState("");
    const [remitenteReferencia, setRemitenteReferencia] = React.useState("");
    const [remitenteCorreo, setRemitenteCorreo] = React.useState("");
    
    //DESTINATARIO
    const [destinatarioClave, setDestinatarioClave] = React.useState("0");
    const [destinatarioNombre, setDestinatarioNombre] = React.useState("");
    const [destinatarioTelefono, setDestinatarioTelefono] = React.useState("");
    const [destinatarioCalle, setDestinatarioCalle] = React.useState("");
    const [destinatarioNumeroExterior, setDestinatarioNumeroExterior] = React.useState("");
    const [destinatarioCP, setDestinatarioCP] = React.useState("");
    const [destinatarioColonia, setDestinatarioColonia] = React.useState("");
    const [destinatarioMunicipio, setDestinatarioMunicipio] = React.useState("");
    const [destinatarioEstado, setDestinatarioEstado] = React.useState("");
    const [destinatarioReferencia, setDestinatarioReferencia] = React.useState("");
    const [destinatarioCorreo, setDestinatarioCorreo] = React.useState("");    
    const [recibio, setRecibio ] = React.useState("");
    const [fechaHora, setFechaHora ] = React.useState("");
    
    const [origen, setOrigen ] = React.useState("");
    const [destino, setDestino ] = React.useState("");    
    const [fecha, setFecha ] = React.useState("");
    const [fechaEntrega, setFechaEntrega ] = React.useState("");
    const [fechaPromesa, setFechaPromesa ] = React.useState("");

    const classes = useStyles();

    React.useEffect(() => {
        props.scrollToTop();
        if(guia != ""){
            props.ObtenerInformacionGuia(guia);
        }
    }, []);

    React.useEffect(() => {
        const formData = props.guiaData;    
        if(formData){
            setRemitenteClave(formData.resultado.datos.idremitente);
            setRemitenteNombre(formData.resultado.datos.remitente);
            setRemitenteTelefono(formData.resultado.datos.remcelular);
            setRemitenteCalle(formData.resultado.datos.remcalle);
            setRemitenteNumeroExterior(formData.resultado.datos.remnumero);
            setRemitenteCP(formData.resultado.datos.remcp);
            setRemitenteColonia(formData.resultado.datos.remcolonia);
            setRemitenteMunicipio(formData.resultado.datos.remmunicipio);
            setRemitenteEstado(formData.resultado.datos.remestado);            
            setRemitenteCorreo(formData.resultado.datos.rememail);

            setDestinatarioClave(formData.resultado.datos.iddestinatario);
            setDestinatarioNombre(formData.resultado.datos.destinatario);
            setDestinatarioTelefono(formData.resultado.datos.descelular);
            setDestinatarioCalle(formData.resultado.datos.descalle);
            setDestinatarioNumeroExterior(formData.resultado.datos.desnumero);
            setDestinatarioCP(formData.resultado.datos.descp);
            setDestinatarioColonia(formData.resultado.datos.descolonia);
            setDestinatarioMunicipio(formData.resultado.datos.desmunicipio);
            setDestinatarioEstado(formData.resultado.datos.desestado);            
            setDestinatarioCorreo(formData.resultado.datos.desemail);

            setOrigen(formData.resultado.datos.origen);
            setDestino(formData.resultado.datos.destino);
            setFecha(formData.resultado.datos.fecha);
            setFechaEntrega(formData.resultado.datos.fechaentrega);
            setFechaPromesa(formData.resultado.datos.fechaposibleentrega);

            if(formData.resultado.ead){
                if(formData.resultado.ead.estado=="D"){
                    setRecibio(formData.resultado.ead.motivodevolucion);
                }else{
                    setRecibio(formData.resultado.ead.recibe);
                }
                setFechaHora(formData.resultado.ead.fecha);
            }
        }
    }, [props.guiaData]);

    const formattedData = props.guiaData == null ? [] : (
    
        props.guiaData.resultado.detalle.map((prop, key) => {
          return [
              prop.cantidad,
              prop.descripcion,
              prop.peso,
              prop.volumen,
              prop.alto+" x "+prop.ancho+" x "+prop.largo
            ]
        })    
    );

    const returnClick = () =>{
        props.scrollToTop();
        props.returnToMapa();
    }

    return (
        <React.Fragment>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                    <h4>Guia #{guia}</h4>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1} >
                                    <CustomInput
                                        labelText="Origen"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value:origen,
                                            
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={1} >
                                    <CustomInput
                                        labelText="Destino"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value:destino,
                                            
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} >
                                    <CustomInput
                                        labelText="Fecha"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value:fecha,
                                            
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} >
                                    <CustomInput
                                        labelText="Promesa de entrega"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value:fechaPromesa,
                                            
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} >
                                    <CustomInput
                                        labelText="Fecha de entrega"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            autoComplete: "off",
                                            value:fechaEntrega,
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardBody>

                            <form>
                                <NavPills
                                    color="success"
                                    horizontal={{
                                        tabsGrid: { xs: 12, sm: 12, md: 3 },
                                        contentGrid: { xs: 12, sm: 12, md: 9 }
                                    }}
                                    tabs={[
                                        {
                                            tabButton: "Remitente",
                                            tabContent: (
                                                <div style={{ width:'93%', marginTop:'0px', scroll:'none' }}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={2}>
                                                            <CustomInput
                                                                labelText="Clave"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:remitenteClave,
                                                                    
                                                                }}
                                                            >

                                                            </CustomInput>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={7}>
                                                        <CustomInput
                                                            labelText="Nombre"
                                                            formControlProps={{ fullWidth: true }}
                                                            inputProps={{
                                                                autoComplete: "off",
                                                                value:remitenteNombre
                                                            }}
                                                        />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                            <CustomInput
                                                                labelText="Telefono"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:remitenteTelefono,
                                                                    
                                                                }}
                                                            >
                                                            </CustomInput>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <CustomInput
                                                                labelText="Calle"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:remitenteCalle,
                                                                    
                                                                }}
                                                            >

                                                            </CustomInput>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                        <CustomInput
                                                            labelText="Numero Exterior"
                                                            formControlProps={{ fullWidth: true }}
                                                            inputProps={{
                                                                autoComplete: "off",
                                                                value:remitenteNumeroExterior
                                                            }}
                                                        />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                            <CustomInput
                                                                labelText="CP"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:remitenteCP,
                                                                    
                                                                }}
                                                            >
                                                            </CustomInput>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <CustomInput
                                                                labelText="Colonia"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:remitenteColonia,
                                                                    
                                                                }}
                                                            >

                                                            </CustomInput>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                        <CustomInput
                                                            labelText="Municipio"
                                                            formControlProps={{ fullWidth: true }}
                                                            inputProps={{
                                                                autoComplete: "off",
                                                                value:remitenteMunicipio
                                                            }}
                                                        />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                            <CustomInput
                                                                labelText="Estado"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:remitenteEstado,
                                                                    
                                                                }}
                                                            >
                                                            </CustomInput>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={4}>
                                                            <CustomInput
                                                                labelText="Correo Electronico"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:remitenteCorreo
                                                                }}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </div>
                                            )
                                        },
                                        {
                                            tabButton: "Destinatario",
                                            tabContent: (
                                                <div style={{ width:'93%', marginTop:'0px', scroll:'none' }}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={2}>
                                                            <CustomInput
                                                                labelText="Clave"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:destinatarioClave,
                                                                    
                                                                }}
                                                            >
                                                            </CustomInput>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={7}>
                                                        <CustomInput
                                                            labelText="Nombre"
                                                            formControlProps={{ fullWidth: true }}
                                                            inputProps={{
                                                                autoComplete: "off",
                                                                value:destinatarioNombre
                                                            }}
                                                        />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                            <CustomInput
                                                                labelText="Telefono"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:destinatarioTelefono,
                                                                    
                                                                }}
                                                            >
                                                            </CustomInput>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <CustomInput
                                                                labelText="Calle"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:destinatarioCalle,
                                                                    
                                                                }}
                                                            >
                                                            </CustomInput>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                        <CustomInput
                                                            labelText="Numero Exterior"
                                                            formControlProps={{ fullWidth: true }}
                                                            inputProps={{
                                                                autoComplete: "off",
                                                                value:destinatarioNumeroExterior
                                                            }}
                                                        />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                            <CustomInput
                                                                labelText="CP"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:destinatarioCP,
                                                                    
                                                                }}
                                                            >
                                                            </CustomInput>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <CustomInput
                                                                labelText="Colonia"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:destinatarioColonia,
                                                                    
                                                                }}
                                                            >
                                                            </CustomInput>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                        <CustomInput
                                                            labelText="Municipio"
                                                            formControlProps={{ fullWidth: true }}
                                                            inputProps={{
                                                                autoComplete: "off",
                                                                value:destinatarioMunicipio
                                                            }}
                                                        />
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={3}>
                                                            <CustomInput
                                                                labelText="Estado"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:destinatarioEstado,
                                                                    
                                                                }}
                                                            >
                                                            </CustomInput>
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={8}>
                                                            <CustomInput
                                                                labelText="Referencias domicilio"
                                                                formControlProps={{ fullWidth: true }}
                                                                inputProps={{
                                                                    autoComplete: "off",
                                                                    value:destinatarioReferencia,
                                                                    
                                                                }}
                                                            >
                                                            </CustomInput>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={12} md={4}>
                                                        <CustomInput
                                                            labelText="Correo Electronico"
                                                            formControlProps={{ fullWidth: true }}
                                                            inputProps={{
                                                                autoComplete: "off",
                                                                value:destinatarioCorreo
                                                            }}
                                                        />
                                                        </GridItem>
                                                        
                                                    </GridContainer>
                                                </div>
                                            )
                                        },
                                        {
                                            tabButton: "Datos del paquete",
                                            tabContent: (
                                                <div style={{ width:'93%', marginTop:'0px', scroll:'none' }}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={12}>
                                                        <Table
                                                            striped
                                                            tableHeaderColor="primary"
                                                            tableHead={["Cant", "Descripcion", "Peso (kg)", "Volumen (m3)", "Medidas (cm)"]}
                                                            tableData={
                                                                formattedData
                                                            }
                                                            coloredColls={[3]}
                                                            colorsColls={["primary"]}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </div>
                                            )
                                        },
                                        {
                                            tabButton: "Datos de quien recibió",
                                            tabContent: (
                                                <div style={{ width:'93%', marginTop:'0px', scroll:'none' }}>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput
                                                            labelText="Recibió"
                                                            formControlProps={{ fullWidth: true }}
                                                                inputProps={{                              
                                                                    autoComplete: "off",
                                                                    value:recibio                              
                                                                }}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                        <CustomInput
                                                            labelText="Fecha y Hora"
                                                            formControlProps={{ fullWidth: true }}
                                                                inputProps={{                              
                                                                    autoComplete: "off",
                                                                    value:fechaHora                              
                                                                }}
                                                            />
                                                        </GridItem>
                                                        
                                                    </GridContainer>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={12} md={6}>
                                                            <img src={imageFirma} alt="..." />
                                                        </GridItem>
                                                    </GridContainer>
                                                </div>
                                            )
                                        }
                                    ]}
                                >
                                    
                                </NavPills>
                            </form>
                            <div style={{ width:"100%",  textAlign:"center" }}>
                                <Button
                                    color="warning"
                                    onClick={returnClick}
                                    className={classes.registerButton}
                                >
                                    Regresar
                                </Button>
                            </div>
                        </CardBody>                        
                    </Card>
                </GridItem>
            </GridContainer>
        </React.Fragment>
    );

}

const mapStateToProps = (state) => {
    return {
        // "listData": state.catalogoCategorias.catalogoCategorias_List,
        // "listData_Error": state.catalogoCategorias.catalogoCategorias_List_Error,
        "guiaData": state.monitoreo.monitoreo_Guia_Data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // "getListForTable": () => dispatch(getListForTable()),
        "ObtenerInformacionGuia": (guia) => dispatch(ObtenerInformacionGuia(guia)),    
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetalleEnvio);