import React from "react";
import { connect } from "react-redux";

import { getRecolecciones, getTotales } from "../../../store/actions/recoleccionesActions";

// core components
import ReactTable from "react-table";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";

import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";

import MoreIcon from '@material-ui/icons/More';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Spinner from "components/Spinner/Spinner.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import stylesTable from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

const useStyles = makeStyles(styles);
const useStylesTable = makeStyles(stylesTable);

function RecoleccionesForm(props){
    const [ sucursal, setSucursal ] = React.useState(props.Sucursal);
    const [ fecha, setFecha ] = React.useState(props.Fecha);
    const [ formData, setFormData ] = React.useState(null);
    const [ totalesData, setTotalesData ] = React.useState(null);

    const [ registradas, setRegistradas ] = React.useState(0);
    const [ transmitidas, setTransmitidas ] = React.useState(0);
    const [ realizadas, setRealizadas ] = React.useState(0);
    const [ canceladas, setCanceladas ] = React.useState(0);

    const [ loadingRecoleccion, setLoadingRecoleccion ] = React.useState(false);

    const classes = useStyles();
    const classesTable = useStylesTable();

    React.useEffect(() => {
        props.scrollToTop();
     if(sucursal > 0){
        props.getRecolecciones(fecha,sucursal,"");
        props.getTotales(fecha,sucursal);
     }
    }, []);

    React.useEffect(()=>{
        setTotalesData(props.totalesData);
        if(props.totalesData){            
            setTransmitidas(props.totalesData.transmitidas);
            setRealizadas(props.totalesData.realizadas);
            setCanceladas(props.totalesData.canceladas);

            setRegistradas(parseInt(props.totalesData.transmitidas) + parseInt(props.totalesData.realizadas) + parseInt(props.totalesData.canceladas));
        }
    },[props.totalesData]);

    React.useEffect(() => {
        setLoadingRecoleccion(false);
        setFormData(props.listData);
    }, [props.listData]);
    
    const formattedData = formData == null ? null : (
        formData.data.map((prop, key) => {
            
            return {
                id: prop.id,
                folio: prop.folio,
                fecha: prop.fecha,
                estado: prop.estado,
                unidad: prop.unidad,
                cliente: prop.cliente,
                // acciones: (
                // // we've added some custom button actions
                // <div className="actions-right">
                //     <Button color="success" className={classesTable.actionButton} onClick={() => {
                //         let obj = formData.data.find(o => o.id === prop.id);
                //         editButtonClick(obj.id);
                //     }}>
                //     <VisibilityIcon className={classes.icon} />
                //     </Button>{" "}            
                // </div>
                // )
            }
        })
      );

    const verRecolecciones = (tipo) => {
        setLoadingRecoleccion(true);
        props.getRecolecciones(fecha,sucursal,tipo);
    }

    const returnToListadoSucursales = () => {        
        props.returnToList();
    }

    const scrollToTop = () => {
        props.scrollToTop(); 
    }

    const form = (!formData) ? ((<Spinner width={"80%"} height={5} color={"black"} loading />)) : <div>
        <GridContainer>        
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <SaveIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Registradas</p>
              <h3 className={classes.cardTitle}>{registradas}</h3>
            </CardHeader>
            <CardFooter stats style={{ cursor:"pointer" }} onClick={() => {
                verRecolecciones("todas");
            }}>
                <div className={classes.stats}>
                    <MoreIcon />
                    Mostrar...
                </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <SendIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Transmitidas SI/NO</p>
              <h3 className={classes.cardTitle}>{transmitidas}</h3>
            </CardHeader>
            <CardFooter stats style={{ cursor:"pointer" }} onClick={() => {
                verRecolecciones("TRANSMITIDO");
            }}>
                <div className={classes.stats}>
                    <MoreIcon />
                    Mostrar...
                </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <LocalShippingIcon/>
              </CardIcon>
              <p className={classes.cardCategory}>Realizadas</p>
              <h3 className={classes.cardTitle}>{realizadas}</h3>
            </CardHeader>
            <CardFooter stats style={{ cursor:"pointer" }} onClick={() => {
                verRecolecciones("REALIZADO");
            }}>
                <div className={classes.stats}>
                    <MoreIcon />
                    Mostrar...
                </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <CancelIcon/>
              </CardIcon>
              <p className={classes.cardCategory}>Canceladas</p>
                <h3 className={classes.cardTitle}>{canceladas}</h3>
            </CardHeader>
            <CardFooter stats style={{ cursor:"pointer" }} onClick={() => {
                verRecolecciones("CANCELADO");
            }}>
                <div className={classes.stats}>
                    <MoreIcon />
                    Mostrar...
                </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>            
        <GridItem xs={12}>
          <Card>
            <CardBody>
              { (loadingRecoleccion) ? <Spinner width={"80%"} height={5} color={"black"} loadingRecoleccion /> : "" }
              <ReactTable
                data={formattedData}
                filterable
                defaultFilterMethod={(filter, row, column) => {
                  const id = filter.pivotId || filter.id
                  return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true
                }
                }
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                    show: false,
                  },
                  {
                    Header: "Folio",
                    accessor: "folio"
                  },
                  {
                    Header: "Fecha",
                    accessor: "fecha"
                  },                  
                  {
                    Header: "Estado",
                    accessor: "estado"
                  },                  
                  {
                    Header: "Unidad",
                    accessor: "unidad"
                  },                  
                  {
                    Header: "Cliente",
                    accessor: "cliente"
                  },                  
                //   {
                //     Header: "Acciones",
                //     accessor: "acciones",
                //     sortable: false,
                //     filterable: false
                //   }
                ]}
                defaultPageSize={20}
                showPaginationTop={false}
                showPaginationBottom
                className="-striped -highlight"
                previousText='Anterior'
                nextText='Siguiente'
                loadingText='Cargando...'
                noDataText='No se encontro ningun registro'
                pageText='Pagina'
                ofText='de'
                rowsText='Filas'
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <div style={{ width:"100%",  textAlign:"center" }}>
        <Button
            color="warning"
            onClick={returnToListadoSucursales}
            className={classes.registerButton}
        >
            Regresar
        </Button>
    </div>
    </div>;

    return(
        <React.Fragment>
            { form }
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        "listData": state.recolecciones.recoleccion_List_Recolecciones,
        "totalesData": state.recolecciones.recoleccion_Totales_Recolecciones,
        "listData_Error": state.recolecciones.recoleccion_Error,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // "getListForTable": () => dispatch(getListForTable()),
        "getRecolecciones": (fecha,sucursal,estado) => dispatch(getRecolecciones(fecha,sucursal,estado)),
        "getTotales": (fecha,sucursal) => dispatch(getTotales(fecha,sucursal)),
        // "closeMessages": () => dispatch(closeMessages()),
        // "logout": () => dispatch(logout()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RecoleccionesForm);