import axios from "axios";
import store from "./store/store";

// Create an axios instance
let axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {    
// Add access token before send the request
const token = store.getState().authentication.accessToken;
// console.log(token);
// const token = 'sadasdasdsadasd546546545465465465s';
    if(token){
        config.headers['X-Apikey'] = `Bearer ${token}`;
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});

export default axiosInstance;