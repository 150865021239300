const initState = {
    version_Data: null,
    version_Data_Error_Message:null
}

const versionReducer = (state = initState, action) => {
    
    switch (action.type) {
        case "VERSION_GET":
                return {
                    ...state,
                    version_Data: action.payload.data,
                    version_Data_Error:null,
                }                            
        case "VERSION_ERROR":
                return {
                    ...state,
                    version_Data: null,
                    version_Data_Error_Message: action.payload.error
                }
        case "VERSION_CLOSE_MESSAGES":
            return {
                ...state,
                version_Data_Error_Message: null,
            }
        default:
            return state;
    }
}

export default versionReducer;