const initState = {
    catalogoSucursal_List: null,    
    catalogoSucursal_Data: null,
    catalogoSucursal_Error: null,    
    loading: null
}

const catalogoSucursalReducer = (state = initState, action) => {
    switch (action.type) {
        case "CATALOGO_SUCURSAL_LIST_SUCCESS":
            return {
                ...state,
                catalogoSucursal_List:action.payload.data,
                catalogoSucursal_Error:null
            }        
        case "CATALOGO_SUCURSAL_ERROR":
            return {
                ...state,
                catalogoSucursal_Error:action.payload.error
            }
        case "CATALOGO_SUCURSAL_CLOSE_MESSAGES":
            // console.log(action.payload.data);
            return {
                ...state,
                catalogoSucursal_Error:null
            }
        
        default:
            return state;
    }
};


export default catalogoSucursalReducer;