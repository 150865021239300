const initState = {
    monitoreo_List: null,
    monitoreo_List_Error: null,   
    monitoreo_Success_Message: null, 
    monitoreo_Error_Message: null, 
    monitoreo_Data: null,
    monitoreo_Reparto_Data:null,
    monitoreo_Guia_Data:null,
    loading: null
}

const monitoreoReducer = (state = initState, action) => {
    switch (action.type) {
        case "MONITOREO_LIST_SUCCESS":
            return {
                ...state,
                monitoreo_List: action.payload.data,
                monitoreo_Error_Message: null,
                monitoreo_Data: null,
            }
        break;
        case "MONITOREO_LIST_ERROR":
            return {
                ...state,
                monitoreo_List: null,
                monitoreo_Error_Message: action.payload.error,
                monitoreo_Data: null,
            }
        break;
        case "MONITOREO_OBTENER_REPARTOS":
            return {
                ...state,
                monitoreo_Data: action.payload.data,
                monitoreo_Error_Message: null,
            }
        break;
        case "MONITOREO_OBTENER_REPARTO":
            return {
                ...state,
                monitoreo_Reparto_Data: action.payload.data,
                monitoreo_Error_Message: null,
            }
        break;
        case "MONITOREO_OBTENER_GUIA":
            return {
                ...state,
                monitoreo_Guia_Data: action.payload.data,
                monitoreo_Error_Message: null,
            }
        break;
        case "MONITOREO_LIMPIAR_REPARTO":
            return {
                ...state,
                monitoreo_Reparto_Data: null,
            }
        default:
            return state;
    }
};


export default monitoreoReducer;