
import Dashboard from "views/Dashboard/Dashboard.js";
import Monitoreo from "views/Logistica/Monitoreo/Monitoreo.js";
import GuiasUnidad from "views/Logistica/GuiasUnidad/GuiasUnidad.js";
import GestionPaquetes from "views/Logistica/GestionPaquetes/GestionPaquetes.js";
import Recolecciones from "views/Logistica/Recolecciones/Recolecciones.js";

import LoginPage from "views/Pages/LoginPage.js";

// @material-ui/icons
// import Apps from "@material-ui/icons/Apps";
// import DashboardIcon from "@material-ui/icons/Dashboard";
import Assignment from "@material-ui/icons/Assignment";
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import StorageIcon from '@material-ui/icons/Storage';
// import DateRange from "@material-ui/icons/DateRange";
// import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
// import Place from "@material-ui/icons/Place";
// import Timeline from "@material-ui/icons/Timeline";
// import WidgetsIcon from "@material-ui/icons/Widgets";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Indicadores",
    rtlName: "لوحة القيادة",
    icon: DesktopWindowsIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Logistica",
    rtlName: "صفحات",
    icon: StorageIcon,
    state: "logisticaCollapse",    
    views: [
      {
        path: "/monitoreo",
        name: "Monitoreo EAD",
        rtlName: "عالتسعير",
        mini: "EAD",
        // icon: DesktopWindowsIcon,
        rtlMini: "ع",
        component: Monitoreo,
        layout: "/admin",
        // nodeName: "NodeCatalogoUsuarios",
      },
      {
        path: "/gestionpaquetes",
        name: "Gestion de paquetes",
        rtlName: "عالتسعير",
        mini: "GP",
        // icon: DesktopWindowsIcon,
        rtlMini: "ع",
        component: GestionPaquetes,
        layout: "/admin",
        // nodeName: "NodeCatalogoUsuarios",
      },
      {
        path: "/recoleccion",
        name: "Recolecciones",
        rtlName: "عالتسعير",
        mini: "RAD",
        // icon:AssignmentTurnedInIcon,
        rtlMini: "ع",
        component: Recolecciones,
        layout: "/admin",
        // nodeName: "NodeCatalogoZonas",
      }, 
      {
        path: "/guiasunidad",
        name: "Guías en unidad",
        rtlName: "عالتسعير",
        mini: "GU",
        // icon:AssignmentTurnedInIcon,
        rtlMini: "ع",
        component: GuiasUnidad,
        layout: "/admin",
        // nodeName: "NodeCatalogoZonas",
      }, 
    ]
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    invisible: true,
    views: [      
      {
        path: "/login-page",
        name: "",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth"
      },      
    ]
  }
];
export default dashRoutes;
