import http from '../../http';

const vRutaGestionPaquetes = 'gestionpaquetes/';
const vHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

export const getListForTable = (sucursal) =>{
    return (dispatch, getState) => {
        var parameters = JSON.stringify({ sucursal });

        // console.log(parameters);

        http.post(vRutaGestionPaquetes+'obtenerlistadodatatable.php', parameters, vHeader)
            .then(function (response){
                // console.log(response);
                dispatch({
                    type: "GESTION_PAQUETES_SUCCESS", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }
    
                  dispatch({ type: 'GESTION_PAQUETES_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            }
        );
    }
}

export const LimpiarData = () =>{
    return (dispatch, getState) => {
        dispatch({type: "GESTION_PAQUETES_LIMPIAR_DATA"});
    }
}

export const LimpiarNombreExcel = () =>{
    return (dispatch, getState) => {
        dispatch({type: "GESTION_PAQUETES_EXCEL_LIMPIAR"});
    }
}

export const getExcelGuias = (tipo,sucursal) => {
    return (dispatch, getState) => {
        var parameters = JSON.stringify({ tipo, sucursal });

        http.post(vRutaGestionPaquetes+'excelGuias.php', parameters, vHeader)
            .then(function (response){
                // console.log(response);
                dispatch({
                    type: "GESTION_PAQUETES_EXCEL", payload: {
                        data: response.data.nombreexcel,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }
    
                  dispatch({ type: 'GESTION_PAQUETES_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            }
        );
    }

    
}