const initState = {
    dashboard_Data: null,
    dashboard_Data_Indicadores: null,
    dashboard_Error:null,
    loading: null
}

const dashboardReducer = (state = initState, action) => {
    switch (action.type) {
        case "DASHBOARD_SUCCESS":
            return {
                ...state,
                dashboard_Data: action.payload.data,
                dashboard_Error: null
            }
        break;
        case "DASHBOARD_ERROR":
            return {
                ...state,                
                dashboard_Error: action.payload.error,
                dashboard_Data: null,
            }
        break;
        case "DASHBOARD_INDICADORES_SUCCESS":
            return {
                ...state,                
                dashboard_Error: null,
                dashboard_Data_Indicadores: action.payload.data,
            }
        break;
        
        default:
            return state;
    }
};


export default dashboardReducer;