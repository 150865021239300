import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";
import { logout, cambiarsucursal } from "../../store/actions/authActions";
import { getSucursales, closeMessages } from "../../store/actions/catalogoSucursalActions";

import SweetAlert from "react-bootstrap-sweetalert";
import stylesAlerts from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import Home from '@material-ui/icons/Home';
import StoreIcon from '@material-ui/icons/Store';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import Select from "react-select";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
const useStyles = makeStyles(styles);


const useStylesAlert = makeStyles(stylesAlerts);

function HeaderLinks(props) {
  const [ sucursalesList, setSucursalesList] = React.useState([]);
  const [ sucursalState, setSucursalState] = React.useState("");
  const [ idSucursalLogueada, setIdSucursalLogueada] = React.useState(localStorage.getItem('sucursal'));
  const [ sucursalLogueada, setSucursalLogueada] = React.useState(localStorage.getItem('nombresucursal'));
  const [ esmatriz, setesmatriz] = React.useState(localStorage.getItem('esmatriz'));
  const [ sucursal, setSucursal] = React.useState("");
  const [ openNotification, setOpenNotification] = React.useState(null);
  
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [alert, setAlert] = React.useState(null);

  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleLogOut = () => {
    setOpenProfile(null);
    props.logout();
  };

  const handleSimple = event => {
    setSimpleSelect(event.target.value);
  };

  const redirectToDashboard = (id, nombre) => {    
    props.cambiarsucursal(id, nombre);
    setTimeout(
      () => {
        window.location.reload(true);
      }, 300
    );    
  } 

  React.useEffect(() => {    
    if(idSucursalLogueada==1 || esmatriz=='s'){
      props.getSucursales();
    }else{
      setSucursal({value:idSucursalLogueada,label:sucursalLogueada});
    }
  }, []);

  // // // load supervisores list
  React.useEffect(() => {
      setSucursalesList(props.listDataSucursales);
  }, [props.listDataSucursales]);

  const handleSucursalChange = (selectedOption) => {
    // console.log(selectedOption);
    if(selectedOption){
        setSucursalState("success");
        setSucursal(selectedOption);
        redirectToDashboard(selectedOption.value,selectedOption.label);
    }else{
        setSucursalState("error");
        setSucursal("");
    }
  }

  const sucursalesStyles = {    
      control: styles => ({ ...styles, marginTop: '27px' }),
      placeholder: styles => ({ ...styles, color: sucursalState === "error" ? '#f44336' : sucursalState === "success" ? '#4caf50' : '#AAAAAA' }),    
  };

  const buscarClick = () => {
    let bFormIsValid = true;    
    if (sucursal === ""){
        setSucursalState("error");
        bFormIsValid = false;
    }
    
    if(bFormIsValid){        
        props.redirectToDashboard(sucursal.value,sucursal.label);
    }
  }
  
  const seleccionarSucursalClick = () => {
    htmlAlert();
  }

  const classes = useStyles();
  const classesAlert = useStylesAlert();
  const { rtlActive } = props;
  const searchButton =
    classes.top +
    " " +
    classes.searchButton +
    " " +
    classNames({
      [classes.searchRTL]: rtlActive
    });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });

  const hideAlert = () => {
    setAlert(null);
  };

  const htmlAlert = () => {
    setAlert(
      <SweetAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="CAMBIAR DE SUCURSAL"
        showConfirm={false}
        showCancel
        cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
        cancelBtnText="Cancelar"  
        onCancel={() => hideAlert()}     
      >
        <Select 
            options={sucursalesList} 
            isClearable
            // isDisabled={ (idSucursalLogueada==1 && esmatriz=='s') ? false : true}
            isLoading={sucursalesList == null}
            onChange={handleSucursalChange}    
            styles={sucursalesStyles}                       
            placeholder="Seleccionar Sucursal"
            value={sucursal}
        />
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        
      </SweetAlert>
    );
  };


  return (    
    <div className={wrapper}>      
    {alert}
      {/* <CustomInput
        rtlActive={rtlActive}
        formControlProps={{
          className: classes.top + " " + classes.search
        }}
        inputProps={{
          placeholder: rtlActive ? "بحث" : "Search",
          inputProps: {
            "aria-label": rtlActive ? "بحث" : "Search",
            className: classes.searchInput
          },
          onKeyUp: e => {
              // e.preventDefault();
              if (e.which === 13 || e.keyCode === 13) {
                  // handlePress(e.target.value);
                  redirectToDashboard();
              }
          }
        }}
      /> */}

      <label style={{ fontSize:"16px", marginRight:"10px" }}>Sucursal: { sucursalLogueada }</label>

      {
        (esmatriz=='s') ?   
          <Tooltip title="Cambiar de sucursal" placement="left">
            <Button
              color="white"
              onClick={seleccionarSucursalClick}
              aria-label="edit"
              justIcon
              round
              // className={searchButton}
              // disabled={ (idSucursalLogueada>1) ? true : false}
            >
              <StoreIcon className={classes.headerLinksSvg + " " + classes.searchIcon} />
            </Button>
          </Tooltip>
        : ""
      }
      

      <div className={managerClasses}>
      <Tooltip title="Cerrar Sesión" placement="left">
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          // onClick={handleClickProfile}
          onClick={handleLogOut}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <ExitToAppIcon
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          {/* <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {rtlActive ? "الملف الشخصي" : "Profile"}
            </span>
          </Hidden> */}
        </Button>
        </Tooltip>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {      
      "listDataSucursales": state.catalogosucursal.catalogoSucursal_List,      
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    "logout": () => dispatch(logout()),
    "cambiarsucursal": (idsucursal,nombresucursal) => dispatch(cambiarsucursal(idsucursal,nombresucursal)),
    "getSucursales": () => dispatch(getSucursales()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);