import http from '../../http';

const vRutaRecolecciones = 'recolecciones/';
const vHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};

export const LimpiarRecolecciones = () =>{
    return (dispatch, getState) => {
        dispatch({type: "RECOLECCION_RECOLECCIONES_LIMPIAR_DATA"});
    }
}

export const getTotales = (fecha, sucursal) => {
    return (dispatch, getState) => {
        
        var parameters = JSON.stringify({ fecha, sucursal });
        // console.log(parameters);
        //dispatch({ type: 'SHOW_LOADING' });
        
        http.post(vRutaRecolecciones+'obtenertotales.php', parameters, vHeader)
            .then(function (response) {
                // console.log(response);
                dispatch({
                    type: "RECOLECCION_TOTALES_SUCCESS", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {                    
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }
    
                  dispatch({ type: 'RECOLECCION_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            });    
    }
}

export const getRecolecciones = (fecha, sucursal, estado) => {
    return (dispatch, getState) => {
        
        var parameters = JSON.stringify({ fecha, sucursal, estado, "recolecciones":"si" });
        // console.log(parameters);
        //dispatch({ type: 'SHOW_LOADING' });
        
        http.post(vRutaRecolecciones+'obtenerlistadodatatable.php', parameters, vHeader)
            .then(function (response) {
                // console.log(response);
                dispatch({
                    type: "RECOLECCION_RECOLECCIONES_LIST_SUCCESS", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {                    
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }
    
                  dispatch({ type: 'RECOLECCION_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            });    
    }
}

export const getListForTable = (fecha, sucursal) => {
    return (dispatch, getState) => {
        
        var parameters = JSON.stringify({ fecha, sucursal });
        // console.log(parameters);
        //dispatch({ type: 'SHOW_LOADING' });
        
        http.post(vRutaRecolecciones+'obtenerlistadodatatable.php', parameters, vHeader)
            .then(function (response) {
                // console.log(response);
                dispatch({
                    type: "RECOLECCION_LIST_SUCCESS", payload: {
                        data: response.data,
                    }
                });
            })
            .catch(function (error) {
                let errorMessage = "";
                if (error.response) {                    
                    errorMessage = error.response.data.mensaje;
                  } else if (error.request) {
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    //console.log(error.request);
                    errorMessage = "The request was made but no response was received";
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    errorMessage = error.message;
                  }
    
                  dispatch({ type: 'RECOLECCION_ERROR', payload: { error: errorMessage } });
            })
            .finally(function () {
                //dispatch({ type: 'HIDE_LOADING' });
            });    
    }
}