import React from 'react';
import Highcharts from "highcharts";
import HighchartSankey from "highcharts/modules/sankey";
import HighchartsWheel from "highcharts/modules/dependency-wheel";
import HighchartsReact from 'highcharts-react-official';



function Grafica(props){
    HighchartSankey(Highcharts);
    HighchartsWheel(Highcharts);

    const options = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Indicadores EAD - Recolecciones'
        },
        subtitle: {
            text: 'Hasta el dia de ayer'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Efectividad %'
            }
    
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.1f}%'
                }
            }
        },
    
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>'
        },
    
        series: [
            {
                name: "Indicadores",
                colorByPoint: true,
                data: [
                    {
                        name: "EAD",
                        y: parseFloat(props.ead)
                    },
                    {
                        name: "Recolecciones",
                        y: parseFloat(props.rad)
                    }                
                ]
            }
        ]
    };

    return (<HighchartsReact
        highcharts={Highcharts}
        options={ options }
      />);
}

export default Grafica;