import React from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import mark from "assets/img/mark.png";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

const useStyles = makeStyles(styles);

function Map(props) {

  const { data } = props;

  const defaultLatitude = 24.2651835;
  const defaultLongitude = -104.5283838;

  const [ selectedRow, setSelectedRow ] = React.useState(null);  
  const [ lastLatitude, setLastLatitude ] = React.useState(data.length==0 ? defaultLatitude : parseFloat(props.data[0].latitud));
  const [ lastLongitude, setLastLongitude ] = React.useState(data.length==0 ? defaultLongitude : parseFloat(props.data[0].longitud));

  return ( (data.length==0) ? 
      <GoogleMap
        defaultZoom={5}
        defaultCenter={{ lat: parseFloat(defaultLatitude), lng: parseFloat(defaultLongitude) }}
        defaultOptions={{
          scrollwheel: false
        }} 
      />
  :
      <GoogleMap
        defaultZoom={12}
        defaultCenter={{ lat: parseFloat(lastLatitude), lng: parseFloat(lastLongitude) }}
        defaultOptions={{
          scrollwheel: true
        }}
      >
          {
          data.map((prop, key) => (
            <Marker
              key={key}
              position={{ lat: parseFloat(prop.latitud), lng: parseFloat(prop.longitud) }} 
              onClick={() => {
                  setSelectedRow(prop);
              }}
              icon={{
                  url: mark
              }}
          />     
          ))
          }

          {selectedRow && (
            <InfoWindow
              position={{ lat: parseFloat(selectedRow.latitud), lng: parseFloat(selectedRow.longitud) }} 
              onCloseClick={() => {
                setSelectedRow(null);
              }}
            >
              <div>
                <p><strong>Guía:</strong>{" "}{selectedRow.guia}</p>
                <p><strong>Fecha Hora:</strong>{" "}{selectedRow.fechaentrega+" "+selectedRow.horaentrega}</p>              
                <p><strong>{ selectedRow.estado === "E" ? "Recibío:" : "Motivo:"}</strong>{" "}{selectedRow.estado === "E" ? selectedRow.quienrecibio : selectedRow.motivo}</p>
                <p><strong>Remitente:</strong>{" "}{selectedRow.remitente}</p>
                <p><strong>Destinatario:</strong>{" "}{selectedRow.destinatario}</p>
              </div>  
            </InfoWindow>
          )}
      </GoogleMap>    
  )
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default function GoogleMapsMultipleMarkers(props) {

  const { data, customPin } = props;
  const latitude = 24.2651835;
  const longitude = -104.5283838;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        
            <WrappedMap
              data={data}
              customPin={customPin}
              latitude={latitude}
              longitude={longitude}
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCPR-S0i7VwKoffK7INIx9wQFQH_ZH2a_k"
              loadingElement={<div style={{ height: "100%" }} />}
              containerElement={
                <div
                  style={{
                    height: "500px",
                    borderRadius: "6px",
                    overflow: "hidden"
                  }}
                />
              }
              mapElement={<div style={{ height: "100%" }} />}
            />
            
      </GridItem>
    </GridContainer>
  )
}