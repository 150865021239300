import React from "react";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
 
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

function Spinner(props) {
    return (
        <div className="sweet-loading">
          <BarLoader
            css={override}
            height={props.height}
            width={props.width}
            color={props.color} //maroon, red, orange, yellow, olive, green, purple, white, fuchsia, lime, teal, aqua, blue, navy, black, gray, silver
            loading={props.loading}
          />
        </div>
      );
}

export default Spinner;
